import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Storage } from 'aws-amplify';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import DownloadIcon from '@mui/icons-material/Download';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { Document, Page, pdfjs } from 'react-pdf';

import { ReactComponent as ReportGraphic } from '../../images/ValReportInProgress.svg';
import { ReactComponent as LoadingSpinner } from '../../images/loading-spinner.svg';

import { ErrorMessageContext } from '../../lib/contextLib';

import {
  getTransactionIdFromBackend,
  grabCompanyIdAndName,
} from '../../utils';

import './ValReport.scss';

export default function ValReport({ setViewReportPage, reportIsReady, previousTransactionId }) {
  const { setShowErrorMessage } = useContext(ErrorMessageContext);
  const [numberOfPages, setNumberOfPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfLoading, setPdfLoading] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');
  const [pageIsLoading, setPageIsLoading] = useState(false);

  pdfjs.GlobalWorkerOptions.workerSrc = '//unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js';

  async function getReportLink() {
    setPageIsLoading(true);
    try {
      const { companyId, companyName } = await grabCompanyIdAndName();
      const transactionId = previousTransactionId || await getTransactionIdFromBackend(companyId);
      const url = await Storage.get(`${companyName}-${transactionId}-409AReport.pdf`, {
        bucket: `${process.env.REACT_APP_CLIENT_FILES_BUCKET}-${process.env.REACT_APP_ENV_LABEL}`,
      });
      setPdfUrl(url);
    } catch (e) {
      setShowErrorMessage(e.toString());
    } finally {
      setPageIsLoading(false);
    }
  }

  async function getReportDownloadLink() {
    try {
      const { companyId, companyName } = await grabCompanyIdAndName();
      const transactionId = previousTransactionId || await getTransactionIdFromBackend(companyId);
      const url = await Storage.get(`${companyName}-${transactionId}-409AReport.pdf`, {
        bucket: `${process.env.REACT_APP_CLIENT_FILES_BUCKET}-${process.env.REACT_APP_ENV_LABEL}`,
      });
      window.open(url);
    } catch (e) {
      setShowErrorMessage(e.toString());
    }
  }

  useEffect(() => {
    if (reportIsReady) getReportLink();
  }, []);

  function onDocumentLoadSuccess({ numPages }) {
    setPageNumber(1);
    setPdfLoading(false);
    setNumberOfPages(numPages);
  }

  if (pageIsLoading) {
    return (
      <div className="page-loading-wrapper">
        <LoadingSpinner className="custom-loading-spinner" />
      </div>
    );
  }

  function homeButton() {
    return (
      <div className="bread-crumb" style={{ marginBottom: '10px' }}>
        <button
          type="button"
          className="bread-crumb-btn"
          onClick={() => setViewReportPage(false)}
        >
          <ArrowBackIcon className="back-icon" />
          <span className="bread-crumb-title">
            Home
          </span>
        </button>
      </div>
    );
  }

  if (!reportIsReady) {
    return (
      <div className="ValReport">
        {homeButton()}
        <div className="splash-content-wrapper">
          <div className="splash-body-wrapper">
            <ReportGraphic className="splash-graphic" />
            <p>409A valuation reports typically take approximately one full business day to prepare.</p>
            <p>We&apos;ll email you when your report is ready.</p>
            <div className="button-wrapper">
              <Button onClick={() => setViewReportPage(false)}>
                Back to homepage
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="ValReport">
      {homeButton()}
      <div className="splash-content-wrapper">
        {pdfLoading && (
          <div className="loading-wrapper">
            <LoadingSpinner className="custom-loading-spinner" />
          </div>
        )}
        <div className="report-viewer">
          <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess} loading=" " error=" ">
            <Page pageNumber={pageNumber} renderAnnotationLayer={false} renderTextLayer={false} />
          </Document>
        </div>
        <div className="btn-container">
          <IconButton onClick={() => getReportDownloadLink()}>
            <DownloadIcon />
          </IconButton>
          <IconButton
            disabled={pageNumber <= 1}
            onClick={() => setPageNumber(1)}
          >
            <FirstPageIcon />
          </IconButton>
          <IconButton
            disabled={pageNumber <= 1}
            onClick={() => setPageNumber(pageNumber - 1)}
          >
            <KeyboardArrowLeftIcon />
          </IconButton>
          <IconButton
            disabled={pageNumber >= numberOfPages}
            onClick={() => setPageNumber(pageNumber + 1)}
          >
            <KeyboardArrowRightIcon />
          </IconButton>
          <IconButton
            disabled={pageNumber >= numberOfPages}
            onClick={() => setPageNumber(numberOfPages)}
          >
            <LastPageIcon />
          </IconButton>
        </div>
      </div>
    </div>
  );
}


ValReport.propTypes = {
  setViewReportPage: PropTypes.func,
  reportIsReady: PropTypes.bool,
  previousTransactionId: PropTypes.string,
};
