import React, { useEffect, useState } from 'react';
import { Storage } from 'aws-amplify';
import PropTypes from 'prop-types';
import moment from 'moment';

import Button from '@mui/material/Button';

import './ClientInputs.scss';

async function gets3Link(file, companyId) {
  window.open(await Storage.get(`${companyId}/${file.dateTime}-${file.fileName}`));
}

function ReviewTile({
  label,
  data,
  currentCompanyId,
}) {
  if (data) {
    return (
      <div className="client-review-tile">
        <p>{label}</p>
        {Array.isArray(data) ? (
          <>
            {data.map((file) => (
              <Button key={`${file.dateTime}-${file.fileName}`} onClick={() => gets3Link(file, currentCompanyId)}>
                {(file.fileName.length >= 30) ? `${file.fileName.slice(0, 30).trim()}...` : file.fileName}
              </Button>
            ))}
          </>
        ) : (
          <p>{data}</p>
        )}
      </div>
    );
  }
}

ReviewTile.propTypes = {
  label: PropTypes.string,
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  currentCompanyId: PropTypes.string,
};

export default function ClientInputs({ companyToViewData }) {
  // Wiz 1
  const [whenIsExitLikelyToHappen, setWhenIsExitLikelyToHappen] = useState('');
  const [whatTypeOfExit, setWhatTypeOfExit] = useState('');
  const [industryVertical, setIndustryVertical] = useState(null);
  // Wiz 2
  const [wiz2Data, setWiz2Data] = useState({
    ntmOptionsPerc: 50,
    totalCurrentCash: '',
    currentMonthlyCashBurn: '',
    totalNonConvertibleDebt: '',
    totalConvertibleDebt: '',
  });

  // Wiz 3
  // >>> Step #1
  const [chosenFunding, setChosenFunding] = useState('');
  // >>> Step #2
  const [currentFundingDate, setCurrentFundingDate] = useState('');
  const [totalAmountRaised, setTotalAmountRaised] = useState('');
  const [totalSafeOrConvertible, setTotalSafeOrConvertible] = useState('');
  // -- spacer --
  const [interestRate, setInterestRate] = useState('');
  const [valuationCap, setValuationCap] = useState('');
  const [preOrPostMoneyNotes, setPreOrPostMoneyNotes] = useState(null);
  // >>> Step #3
  const [previousFundingRoundType, setPreviousFundingRoundType] = useState('');
  // >>> Step #4
  const [receivedFutureTerms, setReceivedFutureTerms] = useState(null);
  // >>> Step #5
  const [
    anySecondaryCommonStockTransactions,
    setAnySecondaryCommonStockTransactions,
  ] = useState(null);
  const [
    secondaryCommonStockTransactionTotalAmount,
    setSecondaryCommonStockTransactionTotalAmount,
  ] = useState('');
  const [
    percentageSecondaryCommonStockSold,
    setPercentageSecondaryCommonStockSold,
  ] = useState('');
  // Wiz 4
  const [capTableProvider, setCapTableProvider] = useState('');
  const [capTableFilesUploaded, setCapTableFilesUploaded] = useState([]);
  const [optionLedgerFilesUploaded, setOptionLedgerFilesUploaded] = useState([]);
  const [financialFilesUpload, setFinancialFilesUploaded] = useState([]);
  const [
    articlesOfIncorporationUploaded,
    setArticlesOfIncorporationUploaded] = useState([]);
  const [
    secondaryTransactionDocs,
    setSecondaryTransactionDocs] = useState([]);
  const [termsSheets, setTermsSheets] = useState([]);
  const [safeOrConvertibleNoteAgreement, setSafeOrConvertibleNoteAgreement] = useState([]);


  useEffect(() => {
    async function getTransactionData() {
      function isTrueOrFalseOrNull(value) {
        if (value === 1) return true;
        if (value === 0) return false;
        return null;
      }
      if ((companyToViewData !== null) && (typeof companyToViewData === 'object')) {
        // Wiz 1
        setWhenIsExitLikelyToHappen(companyToViewData.whenIsExitLikelyToHappen);
        setWhatTypeOfExit(companyToViewData.whatTypeOfExit);
        setIndustryVertical(companyToViewData.industryVertical ? {
          ...industryVertical,
          title: companyToViewData.industryVertical,
          category: companyToViewData.verticalCategory,
          subcategory: companyToViewData.verticalSubcategory,
        } : null);
        // Wiz 2
        setWiz2Data({
          ...wiz2Data,
          ntmOptionsPerc: parseInt(companyToViewData.ntmOptionsPerc, 10),
          totalCurrentCash: companyToViewData.totalCurrentCash,
          currentMonthlyCashBurn: companyToViewData.currentMonthlyCashBurn,
          totalNonConvertibleDebt: companyToViewData.totalNonConvertibleDebt,
          totalConvertibleDebt: companyToViewData.totalConvertibleDebt,
          totalCurrentFiscalYearRevenue: companyToViewData.totalCurrentFiscalYearRevenue,
          totalNextFiscalYearRevenue: companyToViewData.totalNextFiscalYearRevenue,
          totalCurrentFiscalYearEBITDA: companyToViewData.totalCurrentFiscalYearEBITDA,
          totalNextFiscalYearEBITDA: companyToViewData.totalNextFiscalYearEBITDA,
        });
        // Wiz 3
        // >>> Step #1
        setChosenFunding(companyToViewData.chosenFunding);
        // >>> Step #2
        setCurrentFundingDate(moment(companyToViewData.currentFundingDate));
        setTotalSafeOrConvertible(companyToViewData.totalSafeOrConvertible);
        setTotalAmountRaised(companyToViewData.totalAmountRaised);
        // -- spacer --
        setInterestRate(companyToViewData.interestRate);
        setValuationCap(companyToViewData.valuationCap);
        setPreOrPostMoneyNotes(companyToViewData.preOrPostMoneyNotes);
        // >>> Step #3
        setPreviousFundingRoundType(companyToViewData.previousFundingRoundType);
        // >>> Step #4
        setReceivedFutureTerms(isTrueOrFalseOrNull(companyToViewData.receivedFutureTerms));
        // >>> Step #5
        setAnySecondaryCommonStockTransactions(
          isTrueOrFalseOrNull(companyToViewData.anySecondaryCommonStockTransactions),
        );
        setSecondaryCommonStockTransactionTotalAmount(
          companyToViewData.secondaryCommonStockTransactionTotalAmount,
        );
        setPercentageSecondaryCommonStockSold(companyToViewData.percentageSecondaryCommonStockSold);
        // Wiz 4
        setCapTableProvider(companyToViewData.capTableProvider);
        setCapTableFilesUploaded(companyToViewData.documents.cap_table_files || []);
        // eslint-disable-next-line max-len
        if (companyToViewData.documents.option_ledger_files) setOptionLedgerFilesUploaded(companyToViewData.documents.option_ledger_files || []);
        else setOptionLedgerFilesUploaded('N/A');
        setFinancialFilesUploaded(companyToViewData.documents.financial_files || []);
        setArticlesOfIncorporationUploaded(companyToViewData.documents.articles_of_incorporation || []);
        setSecondaryTransactionDocs(companyToViewData.documents.secondary_transaction || []);
        setTermsSheets(companyToViewData.documents.term_sheets || []);
        setSafeOrConvertibleNoteAgreement(companyToViewData.documents.safe_or_convertible_note_agreement || []);
      }
    }
    getTransactionData();
  }, []);

  // TODO UseEffect this
  const safeOrConvertibleForCurrentOrPreviousRounds = (
    (previousFundingRoundType === 'SAFE Note') ||
    (previousFundingRoundType === 'Convertible Note') ||
    (chosenFunding === 'SAFE Note') ||
    (chosenFunding === 'Convertible Note')
  );

  return (
    <div className="ClientInputs">
      <div className="client-review-block">
        <h5>Industry and expected exit</h5>
        <div className="client-review-tiles">
          <ReviewTile
            label="Company industry"
            data={industryVertical?.title}
          />
          <ReviewTile
            label="Most likely type of exit event"
            data={whatTypeOfExit}
          />
          <ReviewTile
            label="Most likely timing of exit event"
            data={whenIsExitLikelyToHappen}
          />
        </div>
      </div>
      <hr />
      <div className="client-review-block">
        <h5>Financials and option pool</h5>
        <div className="client-review-tiles">
          <ReviewTile
            label="Total current cash"
            data={wiz2Data.totalCurrentCash && `$${wiz2Data.totalCurrentCash.toString()}`}
          />
          <ReviewTile
            label="Current monthly cash burn"
            data={wiz2Data.currentMonthlyCashBurn && `$${wiz2Data.currentMonthlyCashBurn.toString()}`}
          />
          <ReviewTile
            label="Total non-convertible debt"
            data={wiz2Data.totalNonConvertibleDebt && `$${wiz2Data.totalNonConvertibleDebt.toString()}`}
          />
          <ReviewTile
            label="Total convertible debt"
            data={wiz2Data.totalConvertibleDebt && `$${wiz2Data.totalConvertibleDebt.toString()}`}
          />
          <ReviewTile
            label="Remaining option pool"
            data={wiz2Data.ntmOptionsPerc && `${wiz2Data.ntmOptionsPerc.toString()}%`}
          />
          <ReviewTile
            label="Total current fiscal year revenue"
            data={
              wiz2Data.totalCurrentFiscalYearRevenue ?
                `${wiz2Data.totalCurrentFiscalYearRevenue.toString()}` :
                'N/A'
            }
          />
          <ReviewTile
            label="Total next fiscal year revenue"
            data={
              wiz2Data.totalNextFiscalYearRevenue ?
                `${wiz2Data.totalNextFiscalYearRevenue.toString()}` :
                'N/A'
            }
          />
          <ReviewTile
            label="Total current fiscal year EBITDA"
            data={
              wiz2Data.totalCurrentFiscalYearEBITDA ?
                `${wiz2Data.totalCurrentFiscalYearEBITDA.toString()}` :
                'N/A'
            }
          />
          <ReviewTile
            label="Total next fiscal year EBITDA"
            data={
              wiz2Data.totalNextFiscalYearEBITDA ?
                `${wiz2Data.totalNextFiscalYearEBITDA.toString()}` :
                'N/A'
            }
          />
        </div>
      </div>
      <hr />
      <div className="client-review-block">
        <h5>Funding and secondary transactions</h5>
        <div className="client-review-tiles">
          <ReviewTile
            label="Total amount raised"
            data={totalAmountRaised && `$${totalAmountRaised}`}
          />
          <ReviewTile
            label="Current funding type"
            data={chosenFunding}
          />
          {chosenFunding !== 'No funding' && (
            <ReviewTile
              label="Date of current round of funding"
              data={currentFundingDate && currentFundingDate.format('MM/DD/YYYY')}
            />
          )}
          {(chosenFunding === 'SAFE Note' || chosenFunding === 'Convertible Note') && (
            <>
              <ReviewTile
                label="Total amount raised"
                data={totalSafeOrConvertible}
              />
              <ReviewTile
                label="Interest rate"
                data={interestRate}
              />
              <ReviewTile
                label="Valuation Cap"
                data={valuationCap}
              />
              <ReviewTile
                label="Pre or Post money"
                data={preOrPostMoneyNotes}
              />
            </>
          )}
          <ReviewTile
            label="Has the company received any term sheets for future rounds of funding?"
            data={(
              receivedFutureTerms === null ?
                '' :
                receivedFutureTerms === true ? 'Yes' : 'No'
            )}
          />
          <ReviewTile
            label="Has the company had any secondary common stock transactions?"
            data={(
              anySecondaryCommonStockTransactions === null ?
                '' :
                anySecondaryCommonStockTransactions === true ? 'Yes' : 'No'
            )}
          />
          {anySecondaryCommonStockTransactions === true && (
            <>
              <ReviewTile
                label="Total amount of secondary stock transaction"
                data={secondaryCommonStockTransactionTotalAmount}
              />
              <ReviewTile
                label="Percent of common stock sold"
                data={(
                  percentageSecondaryCommonStockSold.length === 0 ?
                    '' :
                    `${percentageSecondaryCommonStockSold}%`
                )}
              />
            </>
          )}
        </div>
      </div>
      <hr />
      {(capTableProvider || capTableFilesUploaded?.length || articlesOfIncorporationUploaded?.length ||
        secondaryTransactionDocs?.length || safeOrConvertibleNoteAgreement?.length || termsSheets) &&
        (
          <div className="client-review-block">
            <h5>Cap table and upload</h5>
            <div className="client-review-tiles">
              <ReviewTile
                label="Who's your Cap table provider?"
                data={capTableProvider}
              />
              <ReviewTile
                label="File upload: Cap Table provider"
                data={capTableFilesUploaded}
                currentCompanyId={companyToViewData.companyId}
              />
              <ReviewTile
                label="File upload: Option Ledger"
                data={optionLedgerFilesUploaded}
                currentCompanyId={companyToViewData.companyId}
              />
              <ReviewTile
                label="File upload: Financials"
                data={financialFilesUpload}
                currentCompanyId={companyToViewData.companyId}
              />
              <ReviewTile
                label="File upload: Articles of incorporation"
                data={articlesOfIncorporationUploaded}
                currentCompanyId={companyToViewData.companyId}
              />
              {
                anySecondaryCommonStockTransactions && (
                  <ReviewTile
                    label="File upload: Secondary transaction documents"
                    data={secondaryTransactionDocs}
                    currentCompanyId={companyToViewData.companyId}
                  />
                )
              }
              {
                safeOrConvertibleForCurrentOrPreviousRounds && (
                  <ReviewTile
                    label="File upload: SAFE or convertible note agreement documents"
                    data={safeOrConvertibleNoteAgreement}
                    currentCompanyId={companyToViewData.companyId}
                  />
                )
              }
              {
                receivedFutureTerms && (
                  <ReviewTile
                    label="File upload: Future terms sheets documents"
                    data={termsSheets}
                    currentCompanyId={companyToViewData.companyId}
                  />
                )
              }
            </div>
          </div>
        )}
    </div>
  );
}

ClientInputs.propTypes = { companyToViewData: PropTypes.object };
