import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';

import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import CoPresentOutlinedIcon from '@mui/icons-material/CoPresentOutlined';

import {
  toTitleCase,
  getUserInfoFromLocalStorage,
  getCompanyIdFromBackend,
  getHomepageDataFromDB,
} from '../utils';

import { FirstAndLastNameContext, ReloadHomepageContext, HomepageStateContext, NavWidthContext } from '../lib/contextLib';

// TODO On hold
// import SideNavBar from './SideNavBar';

import { ReactComponent as InitioLogo } from '../images/initio-logo.svg';

import './NavBar.scss';

export default function NavBar({ isAuthenticated, handleLogout, navBarState }) {
  const nav = useNavigate();

  const { globalHomepageState } = useContext(HomepageStateContext);
  const { globalFirstAndLastName, setGlobalFirstAndLastName } = useContext(FirstAndLastNameContext);
  const { setReloadHomepage } = useContext(ReloadHomepageContext);

  const { navWidth } = useContext(NavWidthContext);

  const [accountMenuAnchorEl, setAccountMenuAnchorEl] = useState(null);
  const [accountMenuIsOpen, setAccountMenuIsOpen] = useState(false);
  const [supportMenuAnchorEl, setSupportMenuAnchorEl] = useState(null);
  const [supportMenuIsOpen, setSupportMenuIsOpen] = useState(false);

  const [currentSupportPage, setCurrentSupportPage] = useState('');

  async function getFirstAndLastName() {
    if (!globalFirstAndLastName) {
      const currentCompanyId = getUserInfoFromLocalStorage('companyId') || await getCompanyIdFromBackend();
      const firstAndLastName = getUserInfoFromLocalStorage('firstAndLastName') || await getHomepageDataFromDB(currentCompanyId, 'firstAndLastName');
      if (firstAndLastName.firstName && firstAndLastName.lastName) {
        setGlobalFirstAndLastName({ firstName: toTitleCase(firstAndLastName.firstName), lastName: toTitleCase(firstAndLastName.lastName) });
      }
    }
  }

  useEffect(() => {
    if (isAuthenticated && globalHomepageState && globalHomepageState !== 'isAdmin') {
      getFirstAndLastName();
    }
  }, [isAuthenticated, globalHomepageState]);

  function handleMenuOpen(e, type) {
    if (type === 'account') {
      setAccountMenuAnchorEl(e.currentTarget);
      setAccountMenuIsOpen(true);
    } else {
      setSupportMenuAnchorEl(e.currentTarget);
      setSupportMenuIsOpen(true);
    }
  }

  function handleMenuClose(type) {
    if (type === 'account') {
      setAccountMenuIsOpen(false);
      setAccountMenuAnchorEl(null);
    } else {
      setSupportMenuAnchorEl(null);
      setSupportMenuIsOpen(false);
    }
  }

  return (
    <div className={`NavBar${navBarState.userType === 'admin' ? ' admin-nav' : ''}`} style={{ minWidth: navWidth }}>
      {/* TODO Side nav bar on hold for MVP */}
      {/* isAuthenticated && <SideNavBar /> */}
      <IconButton
        className="initio-logo"
        onClick={() => {
          nav('/');
          setReloadHomepage(true);
        }}
      >
        <InitioLogo />
      </IconButton>
      {isAuthenticated && (
        <div className="right-nav">
          <IconButton
            className={`support-btn ${supportMenuIsOpen ? 'active' : ''}`}
            onClick={(e) => handleMenuOpen(e)}
          >
            <HelpOutlineOutlinedIcon className="support-icon" />
          </IconButton>
          <Menu
            anchorEl={supportMenuAnchorEl}
            open={supportMenuIsOpen}
            onClose={() => handleMenuClose('support')}
            className="support-dropdown"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            disableScrollLock
          >
            <MenuItem
              className={`${currentSupportPage === 'support' ? 'active' : ''}`}
              onClick={() => {
                handleMenuClose('support');
                nav('/support');
                setCurrentSupportPage('support');
              }}
            >
              <SupportAgentOutlinedIcon />
              Customer Support
            </MenuItem>
            <MenuItem
              className={`${currentSupportPage === 'demo' ? 'active' : ''}`}
              onClick={() => {
                handleMenuClose('support');
                nav('/demo');
                setCurrentSupportPage('demo');
              }}
            >
              <CoPresentOutlinedIcon />
              Request a demo
            </MenuItem>
          </Menu>
          {(!globalFirstAndLastName) ? (
            <IconButton
              disableRipple
              onClick={() => handleLogout()}
              className="no-name-sign-out"
            >
              <LogoutOutlinedIcon className="log-out-icon" />
              <span>Sign out</span>
            </IconButton>
          ) : (
            <>
              <div className="user-name">
                {`${globalFirstAndLastName.firstName} ${globalFirstAndLastName.lastName.charAt(0)}.`}
              </div>
              <IconButton
                className={`profile-circle-button ${accountMenuIsOpen ? 'active' : ''}`}
                onClick={(e) => { handleMenuOpen(e, 'account'); }}
              >
                {globalFirstAndLastName.firstName.charAt(0)}
              </IconButton>
              <Menu
                anchorEl={accountMenuAnchorEl}
                open={accountMenuIsOpen}
                className="profile-menu"
                onClose={() => handleMenuClose('account')}
                disableScrollLock
              >
                <MenuItem onClick={() => {
                  handleLogout();
                  handleMenuClose('account');
                }}
                >
                  <LogoutOutlinedIcon />
                  Sign out
                </MenuItem>
              </Menu>
            </>
          )}
        </div>
      )}
    </div>
  );
}

NavBar.propTypes = {
  isAuthenticated: PropTypes.bool,
  handleLogout: PropTypes.func,
  navBarState: PropTypes.object,
};
