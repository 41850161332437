import React from 'react';
import PropTypes from 'prop-types';

import CalcTable from './CalcTable';
import CalcLimits from './CalcLimits';
import Sandbox from '../Sandbox';
import PriceSandbox from '../PriceSandbox';
import ClientInputs from './ClientInputs';
import PDFReportViewer from '../PDFReport/PDFReportViewer';

import alertIconFilledPurple from '../../images/icons/alert_icon_filled_purple.svg';

import {
  preferredTableSchema,
  createPreferredTable,
  safeConvertibleTableSchema,
  createSafeConvertibleTable,
  commonTableSchema,
  createCommonTable,
  optionsTableSchema,
  createOptionsTable,
  warrantsTableSchema,
  createWarrantsTable,
  remainingOptionPoolTableSchema,
  createRemainingOptionsPoolTable,
} from './tab1TableData';

import {
  opmInputsTableSchema,
  createOpmInputsTable,
  opmInputsTableSchema2,
  createOpmInputsTable2,
} from './tab2TableData';

/*
removed until we look at it again
import {
  sharesTableSchema,
  createSharesTable,
  liquidPreferencesTableSchema,
  createLiquidPreferencesTable,
} from './tab3TableData';
*/
import './TabsContent.scss';

export default function TabsContent({
  tabToView,
  companyToViewData,
  preferredTableData,
  setPreferredTableData,
  safeConvertibleTableData,
  setSafeConvertibleTableData,
  commonTableData,
  setCommonTableData,
  optionsTableData,
  setOptionsTableData,
  warrantsTableData,
  setWarrantsTableData,
  remainingOptionsPoolTableData,
  setRemainingOptionsPoolTableData,
  opmInputsTableData,
  setOpmInputsTableData,
  opmInputsTableData2,
  setOpmInputsTableData2,
  /*
  // sharesTableData,
  // setSharesTableData,
  // liquidPreferencesTableData,
  // setLiquidPreferencesTableData,
  */
  setCalcTableHasError,
  metaData,
  numberOfPages,
  setNumberOfPages,
  pageNumber,
  setPageNumber,
  recreateReport,
  setRecreateReport,
  preferredInputsHaveError,
  safeInputsHaveError,
  setSafeInputsHaveError,
  setPreferredInputsHaveError,
  commonTableHaveError,
  optionsTableHaveError,
  warrantsTableHaveError,
  remainingOptionPoolTableHaveError,
  setCommonTableHaveError,
  setOptionsTableHaveError,
  setWarrantsTableHaveError,
  setRemainingOptionPoolTableHaveError,
  setChangeHasBeenMade,
  priceSandbox,
  modelCalcLimits,
  setModelCalcLimits,
  dataLoading,
}) {
  if (tabToView === 1) {
    return (
      <main className="Tab1">
        <CalcTable
          tableData={preferredTableData}
          setTableData={setPreferredTableData}
          tableSchema={preferredTableSchema}
          createTable={createPreferredTable}
          canEditFirstColumn
          canAddOrDeleteRow
          isPreferredTable
          setCalcTableHasError={setCalcTableHasError}
          preferredInputsHaveError={preferredInputsHaveError}
          setPreferredInputsHaveError={setPreferredInputsHaveError}
          setChangeHasBeenMade={setChangeHasBeenMade}
        />
        <CalcTable
          tableData={safeConvertibleTableData}
          setTableData={setSafeConvertibleTableData}
          tableSchema={safeConvertibleTableSchema}
          createTable={createSafeConvertibleTable}
          canEditFirstColumn
          canAddOrDeleteRow
          isSafeTable
          setCalcTableHasError={setCalcTableHasError}
          safeInputsHaveError={safeInputsHaveError}
          setSafeInputsHaveError={setSafeInputsHaveError}
          setChangeHasBeenMade={setChangeHasBeenMade}
        />
        <CalcTable
          tableData={commonTableData}
          setTableData={setCommonTableData}
          tableSchema={commonTableSchema}
          createTable={createCommonTable}
          commonTableHaveError={commonTableHaveError}
          setCommonTableHaveError={setCommonTableHaveError}
          canEditFirstColumn
          canAddOrDeleteRow
          isHalfWidth
          isCommonTable
          setCalcTableHasError={setCalcTableHasError}
          setChangeHasBeenMade={setChangeHasBeenMade}
        />
        <div className="inline-tables">
          <CalcTable
            tableData={optionsTableData}
            setTableData={setOptionsTableData}
            tableSchema={optionsTableSchema}
            createTable={createOptionsTable}
            preferredData={preferredTableData}
            commonData={commonTableData}
            optionsTableHaveError={optionsTableHaveError}
            setOptionsTableHaveError={setOptionsTableHaveError}
            canAddOrDeleteRow
            firstColumnIsDropdown
            isHalfWidth
            isOptionTable
            setCalcTableHasError={setCalcTableHasError}
            setChangeHasBeenMade={setChangeHasBeenMade}
          />
          <CalcTable
            tableData={warrantsTableData}
            setTableData={setWarrantsTableData}
            tableSchema={warrantsTableSchema}
            createTable={createWarrantsTable}
            preferredData={preferredTableData}
            commonData={commonTableData}
            warrantsTableHaveError={warrantsTableHaveError}
            setWarrantsTableHaveError={setWarrantsTableHaveError}
            canAddOrDeleteRow
            firstColumnIsDropdown
            isHalfWidth
            isWarrantTable
            setCalcTableHasError={setCalcTableHasError}
            setChangeHasBeenMade={setChangeHasBeenMade}
          />
        </div>
        <CalcTable
          tableData={remainingOptionsPoolTableData}
          setTableData={setRemainingOptionsPoolTableData}
          tableSchema={remainingOptionPoolTableSchema}
          createTable={createRemainingOptionsPoolTable}
          remainingOptionPoolTableHaveError={remainingOptionPoolTableHaveError}
          setRemainingOptionPoolTableHaveError={setRemainingOptionPoolTableHaveError}
          setChangeHasBeenMade={setChangeHasBeenMade}
          isRemainingOptionsPoolTable
          isHalfWidth
        />
      </main>
    );
  }
  if (tabToView === 2) {
    return (
      <main className="Tab2">
        {!opmInputsTableData[0].lateEquityValue.value && (
          <div className="top-warning">
            <img src={alertIconFilledPurple} alt="no late equity available" />
            &apos;Late equity value&apos;  is determined during calculation unless manually set.
            Click &apos;set value&apos; to manually feed a late equity value to the calculation.
          </div>
        )}
        <CalcTable
          tableData={opmInputsTableData}
          setTableData={setOpmInputsTableData}
          tableSchema={opmInputsTableSchema}
          createTable={createOpmInputsTable}
          setChangeHasBeenMade={setChangeHasBeenMade}
          isOpmTable
        />
        <CalcTable
          tableData={opmInputsTableData2}
          setTableData={setOpmInputsTableData2}
          tableSchema={opmInputsTableSchema2}
          createTable={createOpmInputsTable2}
          setChangeHasBeenMade={setChangeHasBeenMade}
        />
        <CalcLimits
          modelCalcLimits={modelCalcLimits}
          setModelCalcLimits={setModelCalcLimits}
          loading={dataLoading}
        />
      </main>
    );
  }

  /*
  // Removed Cap Table for the time being
  if (tabToView === 3) {
    return (
      <main className="Tab3">
        <CalcTable
          tableData={sharesTableData}
          setTableData={setSharesTableData}
          tableSchema={sharesTableSchema}
          createTable={createSharesTable}
          extraHeadRowCells={[{
            headTitle: 'Shares',
            width: '1308px',
          }]}
          hasTotalRow
          isCapTable
        />
        <CalcTable
          tableData={liquidPreferencesTableData}
          setTableData={setLiquidPreferencesTableData}
          tableSchema={liquidPreferencesTableSchema}
          createTable={createLiquidPreferencesTable}
          extraHeadRowCells={[{
            headTitle: '',
            width: '180px',
          },
          {
            headTitle: 'Liquidation preferences',
            width: '887px',
          },
          {
            headTitle: 'Dividends',
            width: '240px',
          }]}
          hasTotalRow
          isCapTable
        />
      </main>
    );
  }
*/

  if (tabToView === 3) {
    return (
      <main className="Tab4">
        {companyToViewData.hasBeenCalculated ? metaData.transactionId && <Sandbox userData={metaData} /> : (
          <div className="no-sandbox-dialog">
            <p>No sandbox to show.</p>
            <p>
              409A Sandbox will be available after calculation.
              The calculate button will appear once this 409A is in review.
            </p>
          </div>
        )}
      </main>
    );
  }
  if (tabToView === 4) {
    return (
      <main className="Tab4">
        {
          // eslint-disable-next-line
          companyToViewData.hasBeenCalculated && priceSandbox ? metaData.transactionId && <PriceSandbox userData={metaData} priceSandboxData={priceSandbox} /> : (
            <div className="no-sandbox-dialog">
              <p>No sandbox to show.</p>
              <p>
                409A Sandbox will be available after calculation.
                The calculate button will appear once this 409A is in review.
              </p>
            </div>
          )
        }
      </main>
    );
  }
  if (tabToView === 5) {
    return (
      <main className="Tab5">
        {companyToViewData.adminProgress === 'review report' || companyToViewData.adminProgress === 'completed' ? metaData.transactionId && (
          <PDFReportViewer
            userData={metaData}
            numberOfPages={numberOfPages}
            setNumberOfPages={setNumberOfPages}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            recreateReport={recreateReport}
            setRecreateReport={setRecreateReport}
          />
        ) : (
          <div className="no-report-dialog">
            <p>No report to show.</p>
            <p>
              Report will be available after this client finalizes their 409A Sandbox.
            </p>
          </div>
        )}
      </main>
    );
  }
  if (tabToView === 6) {
    setNumberOfPages(null);
    return (
      <main className="Tab6">
        <ClientInputs companyToViewData={companyToViewData} />
      </main>
    );
  }
  return null;
}

TabsContent.propTypes = {
  tabToView: PropTypes.number,
  companyToViewData: PropTypes.object,
  preferredTableData: PropTypes.array,
  setPreferredTableData: PropTypes.func,
  safeConvertibleTableData: PropTypes.array,
  setSafeConvertibleTableData: PropTypes.func,
  commonTableData: PropTypes.array,
  setCommonTableData: PropTypes.func,
  optionsTableData: PropTypes.array,
  setOptionsTableData: PropTypes.func,
  warrantsTableData: PropTypes.array,
  setWarrantsTableData: PropTypes.func,
  remainingOptionsPoolTableData: PropTypes.array,
  setRemainingOptionsPoolTableData: PropTypes.func,
  opmInputsTableData: PropTypes.array,
  setOpmInputsTableData: PropTypes.func,
  opmInputsTableData2: PropTypes.array,
  setOpmInputsTableData2: PropTypes.func,
  /*
  REMOVED
  sharesTableData: PropTypes.array,
  setSharesTableData: PropTypes.func,
  liquidPreferencesTableData: PropTypes.array,
  setLiquidPreferencesTableData: PropTypes.func,
  */
  setCalcTableHasError: PropTypes.func,
  metaData: PropTypes.object,
  numberOfPages: PropTypes.number,
  setNumberOfPages: PropTypes.func,
  pageNumber: PropTypes.number,
  setPageNumber: PropTypes.func,
  recreateReport: PropTypes.bool,
  setRecreateReport: PropTypes.func,
  preferredInputsHaveError: PropTypes.array,
  commonTableHaveError: PropTypes.array,
  optionsTableHaveError: PropTypes.array,
  warrantsTableHaveError: PropTypes.array,
  remainingOptionPoolTableHaveError: PropTypes.bool,
  setCommonTableHaveError: PropTypes.func,
  setOptionsTableHaveError: PropTypes.func,
  setWarrantsTableHaveError: PropTypes.func,
  setRemainingOptionPoolTableHaveError: PropTypes.func,
  safeInputsHaveError: PropTypes.array,
  setSafeInputsHaveError: PropTypes.func,
  setPreferredInputsHaveError: PropTypes.func,
  setChangeHasBeenMade: PropTypes.func,
  priceSandbox: PropTypes.object,
  modelCalcLimits: PropTypes.object,
  setModelCalcLimits: PropTypes.func,
  dataLoading: PropTypes.bool,
};
