import React from 'react';
import PropTypes from 'prop-types';

import Slider from '@mui/material/Slider';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import ToolTip from './ToolTip';
import AnimatedDropdown from './AnimatedDropdown';

import {
  commaEvery3rdChar,
  formatNumericValue,
  copy,
  camelize,
} from '../../utils';

import './Wiz2.scss';

export default function Wiz2({
  currentStep,
  wiz2Data,
  setWiz2Data,
  setEBITDAChecked,
  EBITDAChecked,
}) {
  if (currentStep === 1) {
    return (
      <div className="Wiz2 step-1">
        <h4>Financials</h4>
        <p>
          Basic financial information gives us a picture of your
          company&apos;s current financial status.
        </p>

        <div className="debt-cash-inputs-wrapper">
          {
            [
              { title: 'Total current cash' },
              { title: 'Current monthly cash burn' },
              { title: 'Total non-convertible debt' },
              { title: 'Total convertible debt' },
            ].map(({ title }) => {
              const id = title.trim().toLowerCase().replaceAll(' ', '-');
              const dataKey = camelize(title.replaceAll('-', ' '));
              const updateVal = (e) => { setWiz2Data({ ...wiz2Data, [dataKey]: formatNumericValue(e.target.value) }); };

              return (
                <FormControl sx={{ m: 1 }} key={title.replaceAll(' ', '--$$++')}>
                  <InputLabel htmlFor={id}>
                    {title}
                  </InputLabel>
                  <OutlinedInput
                    className="debt-cash-input"
                    id={id}
                    value={wiz2Data[dataKey] || ''}
                    onBlur={updateVal}
                    onChange={updateVal}
                    startAdornment={(
                      <InputAdornment position="start">
                        <AttachMoneyIcon />
                      </InputAdornment>
                    )}
                    label={title}
                  />
                </FormControl>
              );
            })
          }
        </div>
        <div className="ebitda-check">
          <FormControlLabel
            control={(
              <Checkbox
                className="ebitda-check"
                value={EBITDAChecked}
                checked={EBITDAChecked}
                onClick={() => {
                  if (EBITDAChecked) setEBITDAChecked(false);
                  else setEBITDAChecked(true);
                }}
              />
            )}
            label="Please check if the company has generated or will generate revenue over $1 million in the next year."
          />
        </div>
        <ToolTip
          content={(
            <p>
              <span style={{ fontWeight: 700 }}>Total non-convertible debt</span>
              {' '}
              is any traditional debt such as bank loans, line of credit,
              bridge loans, etc.
              <br />
              <span style={{ fontWeight: 700 }}>Total convertible debt</span>
              {' '}
              is the total of all convertible debt securities including notes
              and SAFEs.
            </p>
          )}
          customContainerStyles={{ marginTop: '20px' }}
        />
      </div>
    );
  }

  if (currentStep === 2) {
    return (
      <div className="Wiz2 step-1">
        <h4>{`Financials ${EBITDAChecked ? '' : '(all fields are optional)'}`}</h4>
        <p>
          Depending on your financial status you may be asked to upload your financial documents in Step 4&apos;s Upload section.
          <br />
          Please be sure the numbers you provide match the documents listed in the tip below.
        </p>

        <div className="debt-cash-inputs-wrapper">
          {
            [
              { title: 'Total current fiscal year revenue' },
              { title: 'Total next fiscal year revenue' },
              { title: 'Total current fiscal year EBITDA' },
              { title: 'Total next fiscal year EBITDA' },
            ].map(({ title }) => {
              const id = title.trim().toLowerCase().replaceAll(' ', '-');
              const dataKey = camelize(title.replaceAll('-', ' '));
              const updateVal = (e) => { setWiz2Data({ ...wiz2Data, [dataKey]: formatNumericValue(e.target.value, { allowNegative: title.includes('EBITDA') }) }); };

              return (
                <FormControl sx={{ m: 1 }} key={title.replaceAll(' ', '--$$++')}>
                  <InputLabel htmlFor={id}>
                    {title}
                  </InputLabel>
                  <OutlinedInput
                    className="debt-cash-input"
                    id={id}
                    value={wiz2Data[dataKey] || ''}
                    onBlur={updateVal}
                    onChange={updateVal}
                    startAdornment={(
                      <InputAdornment position="start">
                        <AttachMoneyIcon />
                      </InputAdornment>
                    )}
                    label={title}
                  />
                </FormControl>
              );
            })
          }
        </div>
        <ToolTip
          content={(
            <p>
              <span style={{ fontWeight: 700 }}>Fiscal year</span>
              {' - '}
              a fiscal year is a twelve month period that companies use for financial reporting.
              <br />
              It correlates with a company&apos;s financial reporting periods and may differ from a calendar year.
              <br />
              <br />
              <span style={{ fontWeight: 700 }}>Current fiscal year</span>
              {' - '}
              this is the fiscal year you are currently in, it is on or before the fiscal year end date.
              <br />
              <br />
              <span style={{ fontWeight: 700 }}>Next fiscal year</span>
              {' - '}
              the twelve month period immediately following the current fiscal year.
              <br />
              <br />
              <span style={{ fontWeight: 700 }}>EBITDA</span>
              {' - '}
              stands for earnings before interest, taxes, depreciation, and amortization. EBITDA is a metric
              <br />
              used to represent cash profit generated by the company&apos;s operating performance.
            </p>
          )}
          customContainerStyles={{ marginTop: '20px' }}
        />
      </div>
    );
  }

  if (currentStep === 3) {
    const marks = [{ value: 0, label: '0%' }].concat(
      new Array(19)
        .fill(null)
        .map((_, i) => ({ value: (i + 1) * 10, label: '' })),
      {
        value: 100,
        label: (
          <div style={{ textAlign: 'right' }}>
            <p style={{ marginBottom: '0px' }}>100%</p>
            <p>or more</p>
          </div>
        ),
      },
    );

    return (
      <div className="Wiz2 step-2">
        <h4>
          What percent of the remaining option pool do you intend
          to issue over the next 12 months?
        </h4>
        <p className="description">
          To process your 409A valuation we need to know, out of the option
          pool
          {' '}
          <span style={{ fontStyle: 'italic' }}>you currently have remaining</span>
          , what percent of it
          do you expect the company to issue over the next 12 months.
          If you plan to issue the exact amount
          {' '}
          <span style={{ fontStyle: 'italic' }}>or more</span>
          {' '}
          than what is allowed by the current pool,
          than please select 100% or more. Round to the nearest 10%.
        </p>
        <Slider
          sx={{ marginTop: '15px' }}
          aria-label="Percentage of remaining options"
          defaultValue={wiz2Data.ntmOptionsPerc}
          value={wiz2Data.ntmOptionsPerc}
          getAriaValueText={(val) => `${val}%`}
          valueLabelDisplay="on"
          valueLabelFormat={(labelNum) => `${labelNum}%`}
          size="large"
          step={10}
          marks={marks}
          min={0}
          max={100}
          onChange={(_, value) => {
            const newWizData = copy(wiz2Data);
            newWizData.ntmOptionsPerc = value;
            setWiz2Data(newWizData);
          }}
        />

        <AnimatedDropdown
          title="What's an option pool?"
          useToolTipBackground={false}
          content={(
            <div style={{ marginLeft: '23px' }}>
              <p>
                An option pool is a block of company equity that has been reserved for
                employees and advisors of a start-up company.
              </p>
            </div>
          )}
        />

      </div>
    );
  }

  return (
    <div>
      <h3>Not a valid step for the 2nd 409a Wizard</h3>
    </div>
  );
}

Wiz2.propTypes = {
  currentStep: PropTypes.number.isRequired,
  setWiz2Data: PropTypes.func.isRequired,
  wiz2Data: PropTypes.object.isRequired,
  setEBITDAChecked: PropTypes.func.isRequired,
  EBITDAChecked: PropTypes.bool.isRequired,
};
