import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';

import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import { Auth } from 'aws-amplify';

import { FormControl, FormHelperText, TextField } from '@mui/material';

import { createAuthHeaders, getUserId, grabCompanyIdAndName } from '../utils';

import { ReactComponent as RedExclamationPointCircle } from '../images/icons/exclamation_point_red.svg';
import { ReactComponent as GreenCheckmark } from '../images/icons/green_checkmark.svg';

import { ErrorMessageContext } from '../lib/contextLib';

import './CheckoutForm.scss';

const inputStyle = {
  fontFamily: 'Roboto, sans-serif',
  fontSize: '16px',
  color: '#49454F',
};

export default function CheckoutForm({
  makePaymentButtonRef,
  setPaymentIsProcessing,
  setPaymentIsCompleted,
  clientSecret,
  discountPaymentData,
  setDiscountPaymentData,
}) {
  const stripe = useStripe();
  const elements = useElements();

  const { setShowErrorMessage } = useContext(ErrorMessageContext);

  const [cardHolderName, setCardHolderName] = useState('');

  const [message, setMessage] = useState('');

  // Discount
  const [codeMessage, setCodeMessage] = useState('');
  const [validCode, setValidCode] = useState(false);
  const [codeError, setCodeError] = useState(false);

  const [discountCode, setDiscountCode] = useState('');
  const [onBlurDiscountCode, setOnBlurDiscountCode] = useState('');
  const [fetchingDiscount, setFetchingDiscount] = useState(false);

  const handleSubmit = async () => {
    if (!stripe || !elements) return;

    setPaymentIsProcessing(true);

    if (discountPaymentData.validCode && discountPaymentData.chargeAmount === '0') {
      const userId = await getUserId();
      const { companyId } = await grabCompanyIdAndName();

      const paymentData = {
        companyId,
        requestUserId: userId,
        ...discountPaymentData,
      };

      try {
        await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/stripe/apply-full-discount`,
          await createAuthHeaders('post', paymentData, true),
        );
        setPaymentIsCompleted(true);
        setPaymentIsProcessing(false);
        return;
      } catch {
        setShowErrorMessage('An unexpected error occurred.');
        return;
      }
    }

    try {
      // card number element as the card element
      const cardNumberElement = elements?.getElement(CardNumberElement);
      const currentUserInfo = await Auth.currentAuthenticatedUser({ bypassCache: true });

      const stripeResponse = await stripe.confirmCardPayment(
        elements._commonOptions.clientSecret.clientSecret,
        {
          payment_method: {
            type: 'card',
            card: cardNumberElement,
            billing_details: { name: cardHolderName },
          },
          receipt_email: currentUserInfo?.attributes?.email?.trim(),
        },
      );

      if (!stripeResponse.error && stripeResponse.paymentIntent.status === 'succeeded') {
        setPaymentIsCompleted(true);
        setPaymentIsProcessing(false);
        return;
      }

      if (stripeResponse.error?.type === 'card_error' || stripeResponse.error?.type === 'validation_error') {
        setMessage(stripeResponse.error.message);
      } else {
        setMessage('An unexpected error occurred.');
      }
    } catch (e) {
      setShowErrorMessage(e.toString());
    } finally {
      setPaymentIsProcessing(false);
    }
  };

  async function fetchDiscountCode() {
    if (discountCode) {
      setFetchingDiscount(true);
      const discountCodeData = { clientSecret, discountCode };
      const headers = await createAuthHeaders('post', discountCodeData, true);
      const codeCheckResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/stripe/discount-check`,
        headers,
      );
      const { Message, Body } = await codeCheckResponse.json();
      if (Body.validCode) {
        setDiscountPaymentData({
          ...Body,
          ...discountCodeData,
        });
        setCodeMessage(Message);
        setCodeError(false);
        setValidCode(true);
      } else {
        setDiscountPaymentData({});
        setCodeError(true);
        setValidCode(false);
        setCodeMessage(Message);
      }
      setFetchingDiscount(false);
    } else {
      setDiscountPaymentData({});
      setCodeError(false);
      setValidCode(false);
      setCodeMessage('');
    }
  }

  useEffect(() => { fetchDiscountCode(); }, [onBlurDiscountCode]);

  return (
    <form id="payment-form">
      <CardNumberElement
        className="card-number"
        options={{
          style: { base: inputStyle },
          placeholder: 'Credit card number',
          disableLink: true,
        }}
      />
      <TextField
        type="text"
        className="name-input"
        placeholder="Name on card"
        onChange={(e) => setCardHolderName(e.target.value)}
      />
      <div>
        <CardExpiryElement
          className="exp-date"
          options={{
            style: {
              base: inputStyle,
            },
            placeholder: 'Expiration date',
          }}
        />
        <span className="helper-text">MM/YY</span>
      </div>
      <div>
        <CardCvcElement
          className="cvv"
          options={{
            style: {
              base: inputStyle,
            },
            placeholder: 'CVV',
          }}
        />
        <span className="helper-text">3 numbers on back of card, Amex: 4 on front</span>
      </div>
      <FormControl>
        <TextField
          className={`discountInput ${validCode ? 'codeValid' : ''}`}
          type="text"
          placeholder="Referral source code (optional)"
          value={discountCode}
          onChange={(e) => setDiscountCode(e.target.value)}
          onBlur={(e) => setOnBlurDiscountCode(e.target.value)}
          autoComplete="off"
        />
        <FormHelperText className="codeError">
          {fetchingDiscount ? (
            <span className="dots-circle-spinner" />
          ) : codeError ? (
            <>
              <RedExclamationPointCircle className="red-exclamation-point-circle" />
              <span className="title-required">{codeMessage}</span>
            </>
          ) : validCode ? (
            <>
              <GreenCheckmark className="red-exclamation-point-circle" />
              <span className="title-required" style={{ color: 'green' }}>{codeMessage}</span>
            </>
          ) : ' '}
        </FormHelperText>
      </FormControl>
      <button
        type="button"
        onClick={() => handleSubmit()}
        aria-label="hidden-pay-button"
        ref={makePaymentButtonRef}
        style={{ display: 'none' }}
      />
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}

CheckoutForm.propTypes = {
  makePaymentButtonRef: PropTypes.object.isRequired,
  setPaymentIsProcessing: PropTypes.func.isRequired,
  setPaymentIsCompleted: PropTypes.func.isRequired,
  clientSecret: PropTypes.string,
  discountPaymentData: PropTypes.object.isRequired,
  setDiscountPaymentData: PropTypes.func.isRequired,
};
