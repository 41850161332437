import React from 'react';
import PropTypes from 'prop-types';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PercentIcon from '@mui/icons-material/Percent';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { ReactComponent as RedExclamationPointCircle } from '../../images/icons/exclamation_point_red.svg';
import './ReviewInfoTile.scss';

export default function ReviewInfoTile({
  title,
  data = '',
  isDataOptional,
  inputHasError,
  invalidInputHelperText = 'invalid input',
  customContainerStyles = {},
  activeWizard,
  wizardTotalSteps,
  openNewWizard,
  currentStep,
  showIcon,
  showErrors,
}) {
  let renderIcon = false;
  const iconHeightWidth = {
    height: '20px!important',
    width: '20px!important',
  };
  const iconStyles = {
    ...iconHeightWidth,
    marginTop: '-3px!important',
    marginRight: '-4px!important',
  };

  if (showIcon) {
    if (/AttachMoneyIcon/ig.test(showIcon)) {
      renderIcon = <AttachMoneyIcon sx={iconStyles} />;
    }
    if (/PercentIcon/ig.test(showIcon)) {
      renderIcon = <PercentIcon sx={iconStyles} />;
    }
  }

  if (Array.isArray(data)) {
    renderIcon = (
      <InsertDriveFileOutlinedIcon
        sx={{
          ...iconHeightWidth,
          marginRight: '8px',
          marginBottom: '5px',
          marginTop: '3px',
        }}
      />
    );
  }

  return (
    <div className="ReviewInfoTile" style={customContainerStyles}>
      <span className="title">
        {title}
        {isDataOptional && ' (optional)'}
      </span>
      {
        (showErrors && ((!isDataOptional && data === '') || inputHasError)) ?
          (
            <div className="not-completed-msg">
              {!inputHasError ?
                (
                  <>
                    <RedExclamationPointCircle className="red-exclamation-point-circle" />
                    <span className="title-required">
                      {title}
                      {' '}
                      required
                    </span>
                  </>
                ) : (
                  <div className="input-with-error">
                    {data}
                    {' '}
                    <span className="title-required">
                      <RedExclamationPointCircle className="red-exclamation-point-circle" />
                      {invalidInputHelperText}
                    </span>
                  </div>
                )}
              <button
                type="button"
                className="go-to-pg"
                onClick={() => {
                  if (typeof currentStep === 'number') {
                    openNewWizard(activeWizard, wizardTotalSteps, currentStep);
                  } else {
                    openNewWizard(activeWizard, wizardTotalSteps);
                  }
                }}
              >
                Go to page
              </button>
            </div>
          ) : (
            Array.isArray(data) ? (
              data.map(({ fileName, dateTime }) => {
                return (
                  <span className="data" key={dateTime}>
                    {renderIcon && renderIcon}
                    {(fileName.length >= 30) ? `${fileName.slice(0, 30).trim()}...` : fileName}
                  </span>
                );
              })
            ) : (
              <span className="data">
                {renderIcon && renderIcon}
                {data || <span style={{ marginLeft: 1 }}>{' '}</span>}
              </span>
            )
          )
      }
    </div>
  );
}

ReviewInfoTile.propTypes = {
  activeWizard: PropTypes.number.isRequired,
  wizardTotalSteps: PropTypes.number.isRequired,
  openNewWizard: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  customContainerStyles: PropTypes.object,
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  isDataOptional: PropTypes.bool,
  inputHasError: PropTypes.bool,
  invalidInputHelperText: PropTypes.string,
  currentStep: PropTypes.number,
  showIcon: PropTypes.string,
  showErrors: PropTypes.bool,
};
