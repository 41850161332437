import React, { useState, useEffect, useContext } from 'react';
import { Auth } from 'aws-amplify';

import CircularProgress from '@mui/material/CircularProgress';

import Login from './Login';
import CalcForm409A from '../409aCalcForm';
import ClientIntakeForm from '../ClientIntakeForm';
import SplashPage from './SplashPage';
import TransactionInfo from '../FinalHomePage/TransactionInfo';
import FinalHomePage from '../FinalHomePage';

import {
  toTitleCase,
  getCompanyIdFromBackend,
  getUserInfoFromLocalStorage,
  setUserInfoToLocalStorage,
  getHomepageDataFromDB,
} from '../../utils';

import {
  AuthContext,
  HomepageStateContext,
  FirstAndLastNameContext,
  ReloadHomepageContext,
  NavBarStateContext,
  HasOldSandboxContext,
} from '../../lib/contextLib';

import './Home.scss';

export default function Home() {
  const { isAuthenticated } = useContext(AuthContext);
  const { globalHomepageState, setGlobalHomepageState } = useContext(HomepageStateContext);
  const { globalFirstAndLastName, setGlobalFirstAndLastName } = useContext(FirstAndLastNameContext);
  const { reloadHomepage, setReloadHomepage } = useContext(ReloadHomepageContext);
  const { navBarState, setNavBarState } = useContext(NavBarStateContext);
  const { hasOldSandbox, setHasOldSandbox } = useContext(HasOldSandboxContext);

  const [fetchingHomepageData, setFetchingHomepageData] = useState(true);

  const [userTransactions, setUserTransactions] = useState(null);

  async function checkHomepageStatus() {
    setFetchingHomepageData(true);
    if ((await Auth.currentSession()).accessToken.payload['cognito:groups']?.includes('initio-admins')) {
      if (!navBarState) setNavBarState({ userType: 'admin' });
      setGlobalHomepageState('isAdmin');
      setFetchingHomepageData(false);
      return;
    }

    const currentCompanyId = getUserInfoFromLocalStorage('companyId') || await getCompanyIdFromBackend();
    const homepageData = await getHomepageDataFromDB(currentCompanyId);
    let homepageState;
    if (homepageData.userTransactions) {
      homepageState = homepageData.userTransactions[0].transactionStatus;
      setHasOldSandbox(homepageData.userTransactions[0].hasOldSandbox);
      setUserTransactions(homepageData.userTransactions);
    } else ({ homepageState } = homepageData);
    if (homepageData.firstAndLastName) setUserInfoToLocalStorage('firstAndLastName', homepageData.firstAndLastName);
    if (!globalFirstAndLastName) {
      const { firstAndLastName } = homepageData;
      if (firstAndLastName.firstName && firstAndLastName.lastName) {
        setGlobalFirstAndLastName({ firstName: toTitleCase(firstAndLastName.firstName), lastName: toTitleCase(firstAndLastName.lastName) });
      }
    }
    setGlobalHomepageState(homepageState);
    setReloadHomepage(false);
    setFetchingHomepageData(false);
  }

  useEffect(() => { if (isAuthenticated) checkHomepageStatus(); }, [isAuthenticated]);
  useEffect(() => { if (reloadHomepage) setGlobalHomepageState(''); }, [reloadHomepage]);
  useEffect(() => { if (reloadHomepage) checkHomepageStatus(); }, [globalHomepageState]);

  if (!isAuthenticated) return <Login />;

  if (fetchingHomepageData) return <CircularProgress size={90} />;

  if (globalHomepageState === 'isAdmin') return <CalcForm409A />;

  if (userTransactions?.length > 1) {
    return (
      <FinalHomePage
        userFirstAndLastName={globalFirstAndLastName}
        currentUserState={globalHomepageState}
        userTransactions={userTransactions}
        reportIsReady={globalHomepageState === 'HasReport'}
      />
    );
  }

  if (globalHomepageState === 'clientIntake') return <ClientIntakeForm />;

  if (
    globalHomepageState === '409A-NotStarted' ||
    globalHomepageState === '409A-InProgress' ||
    globalHomepageState === '409A-Completed' ||
    globalHomepageState === 'Sandbox-Open'
  ) {
    return <SplashPage currentUserState={globalHomepageState} userFirstAndLastName={globalFirstAndLastName} />;
  }

  if (globalHomepageState === 'Sandbox-Finalized' || globalHomepageState === 'HasReport') {
    return (
      <TransactionInfo
        userFirstAndLastName={globalFirstAndLastName}
        hasOldSandbox={hasOldSandbox}
        reportIsReady={globalHomepageState === 'HasReport'}
      />
    );
  }
}
