import { createContext } from 'react';

export const AuthContext = createContext();
export const HomepageStateContext = createContext();
export const FirstAndLastNameContext = createContext();
export const IsSigningOutContext = createContext();
export const ReloadHomepageContext = createContext();
export const ErrorMessageContext = createContext();
export const NavBarStateContext = createContext();
export const HasOldSandboxContext = createContext();
export const NavWidthContext = createContext();


export default {
  AuthContext,
  NavBarStateContext,
  HomepageStateContext,
  FirstAndLastNameContext,
  IsSigningOutContext,
  ReloadHomepageContext,
  ErrorMessageContext,
  HasOldSandboxContext,
  NavWidthContext,
};
