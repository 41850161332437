import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { ReactComponent as WaitingGraphic } from '../../images/WaitingGraphic.svg';
import { ReactComponent as HelloGraphic } from '../../images/HelloGraphic.svg';
import { ReactComponent as SandboxReadyGraphic } from '../../images/SandboxReadyGraphic.svg';
import { timeOfDayGreeting } from '../../utils';
import './SplashPage.scss';

export default function SplashPage({ currentUserState, userFirstAndLastName, hasOldSandbox }) {
  const nav = useNavigate();

  return (
    <div className="SplashPage">
      <div className="splash-content-wrapper">
        <div className="splash-header">
          <h2 style={{ visibility: userFirstAndLastName ? '' : 'hidden' }}>
            {`Good ${timeOfDayGreeting()}, `}
            <span className="user-name">
              {`${userFirstAndLastName?.firstName || ''} ${userFirstAndLastName?.lastName || ''}`}
            </span>
          </h2>
          {(currentUserState === '409A-NotStarted' || currentUserState === '409A-InProgress') && (
            <Button className="headline-btn" onClick={() => nav('/409a-info')}>
              {currentUserState === '409A-InProgress' ? 'Resume 409A valuation' : 'Start a new 409A'}
            </Button>
          )}
        </div>
        <div className="splash-body-wrapper">

          {currentUserState === '409A-Completed' ?
            <WaitingGraphic className="splash-graphic" /> :
            currentUserState === 'Sandbox-Open' ?
              <SandboxReadyGraphic className="splash-graphic" /> :
              <HelloGraphic className="splash-graphic" />}

          {(currentUserState === '409A-Completed' || currentUserState === 'Sandbox-Open') && (
            <span className="title">
              {currentUserState === 'Sandbox-Open' ?
                'Check it out. Your 409A valuation sandbox is ready.' :
                'Sit back and relax, your 409A valuation form and documents are being reviewed.'}
            </span>
          )}

          {currentUserState === '409A-NotStarted' ? (
            <>
              <p>Success! You&apos;re all set up with your initio account.</p>
              <p>You get a free 409A valuation per year. Get started!</p>
            </>
          ) : currentUserState === '409A-InProgress' ? (
            <>
              <p>You&apos;ve started a 409A valuation form. We&apos;ve saved your progress.</p>
              <p>Simply click &apos;Resume 409A valuation&apos; whenever you&apos;re ready to proceed.</p>
            </>
          ) : (
            <>
              {currentUserState === '409A-Completed' &&
                <p>We&apos;ll email you within the next 7 business days when your 409A valuation sandbox is ready.</p>}
              <p>The sandbox is where you can, in real-time, customize your 409A before a final valuation gets locked-in.</p>
            </>
          )}

          <div className="button-wrapper">
            {currentUserState !== '409A-Completed' && (
              <Button
                startIcon={currentUserState === 'Start a new 409A' && <AddIcon />}
                onClick={() => nav(currentUserState === 'Sandbox-Open' && hasOldSandbox ?
                  '/409a-sandbox' : currentUserState === 'Sandbox-Open' ? '/409a-price-sandbox' : '/409a-info')}
              >
                {currentUserState === '409A-NotStarted' ? 'Start a 409A valuation' :
                  currentUserState === '409A-InProgress' ? 'Resume 409A valuation' : 'Check out your 409A valuation sandbox'}
              </Button>
            )}
            {(currentUserState === '409A-Completed' || currentUserState === 'Sandbox-Open') && (
              <Button onClick={() => nav('/409a-info?review=true')}>View submitted information</Button>
            )}
          </div>

        </div>
      </div>
    </div>
  );
}

SplashPage.propTypes = {
  currentUserState: PropTypes.string.isRequired,
  userFirstAndLastName: PropTypes.object,
  hasOldSandbox: PropTypes.bool,
};
