import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import AnimatedDropdown from './AnimatedDropdown';
import FileUploadAndInfoContainer from './FileUploadAndInfoContainer';
import Wiz4Header from './Wiz4Header';
import ToolTip from './ToolTip';
import { copy } from '../../utils';
import './Wiz4.scss';

export default function Wiz4({
  currentStep,

  capTableProvider,
  setCapTableProvider,

  capTableFilesUploaded,
  setCapTableFilesUploaded,

  optionLedgerFilesUploaded,
  setOptionLedgerFilesUploaded,

  financialFilesUploaded,
  setFinancialFilesUploaded,

  articlesOfIncorporationUploaded,
  setArticlesOfIncorporationUploaded,

  secondaryTransactionDocs,
  setSecondaryTransactionDocs,

  termsSheets,
  setTermsSheets,

  safeOrConvertibleNoteAgreement,
  setSafeOrConvertibleNoteAgreement,

  // New props
  anySecondaryCommonStockTransactions,
  safeOrConvertibleNote,
  receivedFutureTerms,
}) {
  const [dynamicStepsUsed, setDynamicStepsUsed] = useState({
    anySecondaryCommonStockTransactions: 0,
    receivedFutureTerms: 0,
    safeOrConvertibleNote: 0,
  });

  const headerIconStyles = {
    height: '54px',
    width: '54px',
    marginRight: '10px',
  };

  function uploadSecondaryTransactionDocuments() {
    return (
      <div className="Wiz4">
        <Wiz4Header
          title="Upload Secondary Transaction documents"
          icon={<BallotOutlinedIcon sx={headerIconStyles} />}
          // eslint-disable-next-line max-len
          content="Earlier you stated that your company had secondary transaction(s). Please upload all documents related to your secondary transaction(s)."
        />
        <FileUploadAndInfoContainer
          key="secondary-transaction-docs"
          filesUploadedParent={secondaryTransactionDocs}
          setFilesUploadedParent={setSecondaryTransactionDocs}
        />
      </div>
    );
  }

  function uploadTermsSheets() {
    return (
      <div className="Wiz4 step-5">
        <Wiz4Header
          title="Upload Term sheet(s)"
          icon={<ArticleOutlinedIcon sx={headerIconStyles} />}
          // eslint-disable-next-line max-len
          content="Earlier you stated that the company has received written sheet(s) for future rounds of funding. Upload all written term sheets and agreements supporting those transactions."
        />
        <FileUploadAndInfoContainer
          key="terms-sheets"
          filesUploadedParent={termsSheets}
          setFilesUploadedParent={setTermsSheets}
        />
        <ToolTip
          customContainerStyles={{ marginTop: '40px' }}
          title="Reminder"
          icon="WarningAmberIcon"
          // eslint-disable-next-line max-len
          content="If you have anything written, in any form, signed or not signed, that discusses specific terms and conditions that has any likelihood that it will close, upload it. If you don't the IRS can penalize you."
        />
        <AnimatedDropdown
          title="What is a term sheet?"
          customContainerStyles={{ marginTop: '34px' }}
          content={(
            <div style={{ marginLeft: '23px', width: '650px' }}>
              <p>
                A term sheet is any non-binding written agreement that outlines the
                terms and conditions of an investment.
                It can take the form of a digital file, an email in someone&apos;s inbox,
                anything that outlines terms and conditions of an investment.
              </p>
            </div>
          )}
          useToolTipBackground={false}
        />
      </div>
    );
  }

  function uploadSAFEorConvertibleNoteAgreement() {
    return (
      <div className="Wiz4 step-6">
        <Wiz4Header
          title="Upload SAFE or Convertible Note Agreement"
          icon={<AssessmentOutlinedIcon sx={headerIconStyles} />}
          content="Upload all agreements relating to the outstanding SAFE and/or Convertible Notes."
        />
        <FileUploadAndInfoContainer
          key="safe-or-convertible-note-agreement"
          filesUploadedParent={safeOrConvertibleNoteAgreement}
          setFilesUploadedParent={setSafeOrConvertibleNoteAgreement}
        />
      </div>
    );
  }

  function returnThisSection(displayThisSection, key, stepIndex, sectionToReturn) {
    if (displayThisSection && (
      (dynamicStepsUsed[key] === 0) ||
      (dynamicStepsUsed[key] === stepIndex)
    )) {
      if (dynamicStepsUsed[key] === 0) {
        dynamicStepsUsed[key] = stepIndex;
        setDynamicStepsUsed(copy(dynamicStepsUsed));
      }
      return sectionToReturn();
    }

    return false;
  }

  function errorStep() {
    return (
      <div className="Wiz4">
        <h3>Not a valid step for the 4th 409a Wizard</h3>
      </div>
    );
  }

  function whichOfTheseSectionsDoWeReturn(wizardStep) {
    let section;

    section = returnThisSection(
      anySecondaryCommonStockTransactions,
      'anySecondaryCommonStockTransactions',
      wizardStep,
      uploadSecondaryTransactionDocuments,
    );

    if (section) return section;

    section = returnThisSection(
      receivedFutureTerms,
      'receivedFutureTerms',
      wizardStep,
      uploadTermsSheets,
    );

    if (section) return section;

    section = returnThisSection(
      safeOrConvertibleNote,
      'safeOrConvertibleNote',
      wizardStep,
      uploadSAFEorConvertibleNoteAgreement,
    );

    if (section) return section;

    return errorStep();
  }

  if (currentStep === 1) {
    return (
      <div className="Wiz4">
        <h4>Who&apos;s your Cap Table provider?</h4>
        <FormControl fullWidth sx={{ marginTop: '24px', marginBottom: '24px' }}>
          <InputLabel id="demo-simple-select-label">
            <AssessmentOutlinedIcon />
            <span style={{ marginLeft: '19px' }}>Cap Table provider</span>
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={capTableProvider || ''}
            label={(
              <span>
                <AssessmentOutlinedIcon />
                <span style={{ marginLeft: '19px' }}>
                  Cap Table provider
                </span>
              </span>
            )}
            onChange={(e) => {
              e.preventDefault();
              setCapTableProvider(e.target.value);
            }}
            MenuProps={{ disableScrollLock: true }}
          >
            {
              [
                'Carta',
                'Pulley',
                'Shareworks',
                'Other',
              ].map((name) => (
                <MenuItem
                  key={name.replaceAll(' ', '-+-+')}
                  value={name}
                >
                  {name}
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>
        <AnimatedDropdown
          title="What's a Cap Table?"
          // eslint-disable-next-line max-len
          content="The Capitalization Table or Cap Table is essential to the 409A valuation. It describes the investors and owners and their percentage of ownership. It outlines all company equity, who owns what and how much of the company they own, as well as why they own it."
          useToolTipBackground={false}
        />
      </div>
    );
  }
  if (currentStep === 2) {
    return (
      <div className="Wiz4 step-2">
        <Wiz4Header
          title="Upload Cap Table"
          icon={<AssessmentOutlinedIcon sx={headerIconStyles} />}
          // eslint-disable-next-line max-len
          content="Please only upload your most recent, detailed (not summarized) Cap Table. It must be current as of this transaction date and capture all intended financing. It can be a Post-Close or a Pro Forma Cap Table."
        />
        <FileUploadAndInfoContainer
          key="cap-table-files-upload"
          filesUploadedParent={capTableFilesUploaded}
          setFilesUploadedParent={setCapTableFilesUploaded}
        />
      </div>
    );
  }
  if (currentStep === 3) {
    return (
      <div className="Wiz4 step-3">
        <Wiz4Header
          id="wizStep-3"
          title="Option Ledger (if applicable)"
          icon={<AssessmentOutlinedIcon sx={headerIconStyles} />}
          // eslint-disable-next-line max-len
          content="Please Upload your most recent Option Ledger. If you've issued options, you will have an Option Ledger. To provide you with your valuation we'll need a ledger of all outstanding options."
          nameOfClass="wiz4-step3"
        />
        <FileUploadAndInfoContainer
          key="option-ledger-files-upload"
          filesUploadedParent={optionLedgerFilesUploaded}
          setFilesUploadedParent={setOptionLedgerFilesUploaded}
        />
        <AnimatedDropdown
          title="What's the difference between a Cap Table and Option Ledger"
          customContainerStyles={{ marginTop: '34px' }}
          content={(
            <div style={{ marginLeft: '23px', width: '650px' }}>
              <p>
                In the early stages of a company, equity management can be relatively simple
                and a Cap Table alone will typically suffice. As a company expands, equity
                management becomes more complex and it becomes important to maintain both a
                Cap Table and an Option Ledger, often separately.
              </p>
              <p style={{ marginTop: '24px' }}>
                <span style={{ fontWeight: 700 }}>A Cap Table</span>
                {' '}
                shows the ownership of the Company based upon the outstanding equity
                classes and instruments.
              </p>
              <p style={{ marginTop: '24px' }}>
                <span style={{ fontWeight: 700 }}>An Option Ledger</span>
                {' '}
                is a document that captures a historical list of all current, granted
                and outstanding options. This file typically shows the granted,
                exercised, forfeited, and outstanding shares.
              </p>
            </div>
          )}
          useToolTipBackground={false}
        />
      </div>
    );
  }
  if (currentStep === 4) {
    return (
      <div className="Wiz4 step-4">
        <Wiz4Header
          id="wizStep-3"
          title="Upload Financial documents"
          icon={<AssessmentOutlinedIcon sx={headerIconStyles} />}
          // eslint-disable-next-line max-len
          content="Please upload: your current fiscal year's 12 month income statement (or profit and loss statement), your current date balance sheet and your financial forecast."
          nameOfClass="wiz4-step4"
        />
        <FileUploadAndInfoContainer
          key="financials-files-upload"
          filesUploadedParent={financialFilesUploaded}
          setFilesUploadedParent={setFinancialFilesUploaded}
        />
        <AnimatedDropdown
          title="What's a current fiscal year?"
          customContainerStyles={{ marginTop: '34px' }}
          content={(
            <div style={{ marginLeft: '23px', width: '650px' }}>
              <p style={{ marginTop: '24px' }}>
                <span style={{ fontWeight: 700 }}>Fiscal year</span>
                {' - '}
                a fiscal year is a twelve month period that companies use for financial reporting.
                <br />
                It correlates with a company&apos;s financial reporting periods and may differ from a calendar year.
              </p>
              <p style={{ marginTop: '24px' }}>
                <span style={{ fontWeight: 700 }}>Current fiscal year</span>
                {' - '}
                this is the fiscal year you are currently in, it is on or before the fiscal year
                <br />
                end date.
              </p>
            </div>
          )}
          useToolTipBackground={false}
        />
      </div>
    );
  }
  if (currentStep === 5) {
    return (
      <div className="Wiz4">
        <Wiz4Header
          title="Upload Articles of Incorporation"
          icon={<AccountBalanceIcon sx={headerIconStyles} />}
          // eslint-disable-next-line max-len
          content="Upload the latest Articles of incorporation, it is usually filed on the date of closing the latest round of financing. It's a formal government document obtained after the company has been legally incorporated. It includes the company's name, address and the amount & type of stocks the company has."
        />
        <FileUploadAndInfoContainer
          key="articles-of-incorporation-upload"
          filesUploadedParent={articlesOfIncorporationUploaded}
          setFilesUploadedParent={setArticlesOfIncorporationUploaded}
        />
      </div>
    );
  }
  // Dynamic steps!
  if (currentStep === 6) {
    return whichOfTheseSectionsDoWeReturn(4);
  }
  if (currentStep === 7) {
    return whichOfTheseSectionsDoWeReturn(5);
  }
  if (currentStep === 8) {
    return whichOfTheseSectionsDoWeReturn(6);
  }

  return errorStep();
}

Wiz4.propTypes = {
  currentStep: PropTypes.number,

  capTableProvider: PropTypes.string,
  setCapTableProvider: PropTypes.func,

  capTableFilesUploaded: PropTypes.arrayOf(PropTypes.object),
  setCapTableFilesUploaded: PropTypes.func,

  optionLedgerFilesUploaded: PropTypes.arrayOf(PropTypes.object),
  setOptionLedgerFilesUploaded: PropTypes.func,

  financialFilesUploaded: PropTypes.arrayOf(PropTypes.object),
  setFinancialFilesUploaded: PropTypes.func,

  articlesOfIncorporationUploaded: PropTypes.arrayOf(PropTypes.object),
  setArticlesOfIncorporationUploaded: PropTypes.func,

  secondaryTransactionDocs: PropTypes.arrayOf(PropTypes.object),
  setSecondaryTransactionDocs: PropTypes.func,

  termsSheets: PropTypes.arrayOf(PropTypes.object),
  setTermsSheets: PropTypes.func,

  safeOrConvertibleNoteAgreement: PropTypes.arrayOf(PropTypes.object),
  setSafeOrConvertibleNoteAgreement: PropTypes.func,

  anySecondaryCommonStockTransactions: PropTypes.bool,
  safeOrConvertibleNote: PropTypes.bool,
  receivedFutureTerms: PropTypes.bool,
};
