import React, { useState, useEffect, useContext } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';

import LogRocket from 'logrocket';

import Dialog from '@mui/material/Dialog';

import RouterProvider from './Routes';
import NavBar from './components/NavBar';
import AlertDisplay from './components/AlertDisplay';

import InactivityTimeout from './lib/InactivityTimeout';

import { getUserId, getUserEmail, grabCompanyIdAndName } from './utils';

import {
  AuthContext,
  IsSigningOutContext,
  NavBarStateContext,
} from './lib/contextLib';

import { ReactComponent as LoadingSpinner } from './images/loading-spinner.svg';

import './App.scss';

function App() {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const { isSigningOut, setIsSigningOut } = useContext(IsSigningOutContext);
  const { navBarState } = useContext(NavBarStateContext);

  const nav = useNavigate();

  async function checkCurrentAuthSession() {
    const expTime = JSON.parse(localStorage.getItem('_globalTime'));
    const currentTime = Date.now();
    if (expTime && currentTime && ((expTime - currentTime) < 0)) {
      await Auth.signOut();
      setIsAuthenticated(false);
      setIsAuthenticating(false);
    } else {
      try {
        await Auth.currentSession();
        setIsAuthenticated(true);

        const useLogRocket = process.env.REACT_APP_USE_LOG_ROCKET === 'true';

        const userId = await getUserId();
        const email = await getUserEmail();
        const { companyId, companyName } = await grabCompanyIdAndName();

        if (useLogRocket) {
          LogRocket.init(process.env.REACT_APP_LOG_ROCKET_APP_ID);

          LogRocket.identify(userId, {
            email,
            companyName,
            companyId,
          });
        }
      } catch {
        setIsAuthenticated(false);
      } finally {
        setIsAuthenticating(false);
      }
    }
  }

  async function handleLogout(wasInactive = false) {
    setIsSigningOut(true);
    await Auth.signOut();
    setIsAuthenticated(false);
    if (wasInactive) nav('/login?inactivity=true');
    else nav('/login');
    setIsSigningOut(false);
  }

  useEffect(() => { checkCurrentAuthSession(); }, []);

  return (
    !isAuthenticating && (
      <div className="App">
        <AlertDisplay />
        <NavBar
          navBarState={navBarState}
          isAuthenticated={isAuthenticated}
          handleLogout={handleLogout}
        />
        <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
          <RouterProvider />
        </AuthContext.Provider>
        <InactivityTimeout
          isAuthenticated={isAuthenticated}
          handleLogout={handleLogout}
        />
        <Dialog className="loading-dialog" open={isSigningOut} disableScrollLock>
          <LoadingSpinner className="custom-loading-spinner" />
        </Dialog>
      </div>
    )
  );
}

export default App;
