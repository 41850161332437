import React, {
  useEffect, useState, useRef, useContext,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';

// MUI imports
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';

// Icons
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoneIcon from '@mui/icons-material/Done';

// Images
import helloAvatar from '../../images/HelloAvatarGraphic.svg';
import { ReactComponent as LoadingSpinner } from '../../images/loading-spinner.svg';

// Components
import ArrowTooltip from './ArrowTooltip';
import ProgressIndicatorLine from '../../components/ProgressIndicatorLine';
import Wiz1 from './Wiz1';
import ReviewAndSign from './ReviewAndSign';
import MakePayment from './MakePayment';

// Helper
import {
  copy,
  toTitleCase,
  createAuthHeaders,
  getCompanyIdFromBackend,
  getUserId,
  getUserInfoFromLocalStorage,
  sendHtmlEmail,
  setUserInfoToLocalStorage,
} from '../../utils';

import {
  AuthContext,
  FirstAndLastNameContext,
  IsSigningOutContext,
  ReloadHomepageContext,
  ErrorMessageContext,
} from '../../lib/contextLib';

import {
  userInfoFormContent,
  userInfoDefaultValues,
  companyInfoFormContent,
  companyInfoDefaultValues,
  extraUserFormContent,
  legalFirmInfoFormContent,
  legalFirmInfoDefaultValues,
  accountingFirmInfoFormContent,
  accountingFirmInfoDefaultValues,
  hasErrorDefaultValues,
} from './formFieldsContents';

import './index.scss';

export default function ClientIntakeForm() {
  const { setIsAuthenticated } = useContext(AuthContext);
  const { setIsSigningOut } = useContext(IsSigningOutContext);
  const { setReloadHomepage } = useContext(ReloadHomepageContext);
  const { setShowErrorMessage } = useContext(ErrorMessageContext);

  const { setGlobalFirstAndLastName } = useContext(FirstAndLastNameContext);
  const [activeWizard, setActiveWizard] = useState(0);
  const [inWiz, setInWiz] = useState(false);
  const [wizSteps, setWizSteps] = useState({ currentStep: 1, totalSteps: 3 });
  const [showDataLoadingOverlay, setShowDataLoadingOverlay] = useState(false);
  const [stepsVisited, setStepsVisited] = useState([]);
  const [hasStarted, setHasStarted] = useState(false);
  const [checkForLastCompletedStep, setCheckForLastCompletedStep] = useState(false);

  // Wiz 1 - Client Intake Form
  const [userInfoInput, setUserInfoInput] = useState(userInfoDefaultValues);
  const [companyInfoInput, setCompanyInfoInput] = useState(companyInfoDefaultValues);
  const [legalFirmInfoInput, setLegalFirmInfoInput] = useState(legalFirmInfoDefaultValues);
  const [accountingFirmInfoInput, setAccountingFirmInfoInput] =
    useState(accountingFirmInfoDefaultValues);
  const [inputHasError, setInputHasError] = useState(hasErrorDefaultValues);

  // Wiz 2 - Review And Sign
  const [reviewPageHasBeenVisited, setReviewPageHasBeenVisited] = useState(false);
  const [userSignature, setUserSignature] = useState('');
  const [showErrors, setShowErrors] = useState(false);

  // Wiz 3 - Payment
  const [paymentFormIsLoading, setPaymentFormIsLoading] = useState(false);
  const [paymentIsCompleted, setPaymentIsCompleted] = useState(false);
  const [paymentIsProcessing, setPaymentIsProcessing] = useState(false);
  const [discountPaymentData, setDiscountPaymentData] = useState({});
  const makePaymentButtonRef = useRef('');

  const nav = useNavigate();

  function isFormCompleted() {
    function checkForEmptyInputs(inputs, formContent) {
      return Object.values(inputs).every((x, i) => {
        if (!formContent[i]?.isOptional) {
          return x !== '';
        }
        return true;
      });
    }

    return ( // check for empty inputs
      checkForEmptyInputs(userInfoInput, userInfoFormContent) &&
      checkForEmptyInputs(companyInfoInput, companyInfoFormContent) &&
      checkForEmptyInputs(legalFirmInfoInput, legalFirmInfoFormContent) &&
      checkForEmptyInputs(accountingFirmInfoInput, accountingFirmInfoFormContent) &&
      (companyInfoInput.extraUsers.length > 0 ?
        companyInfoInput.extraUsers.every((extraUser) => {
          return checkForEmptyInputs(extraUser, extraUserFormContent);
        }) : true) &&
      // check for errors
      Object.values(inputHasError).every((x) => x === false || Array.isArray(x)) &&
      (inputHasError.extraUsers.length > 0 ?
        inputHasError.extraUsers.every((extraUser) => {
          return Object.values(extraUser).every((x) => x === false);
        }) : true)
    );
  }

  const steps = [
    {
      label: 'Your information',
      totalSteps: () => 3,
      isComplete: () => isFormCompleted(),
    },
    {
      label: 'Review and sign',
      totalSteps: () => 2,
      isComplete: () => !!userSignature,
    },
    {
      label: 'Make payment',
      totalSteps: () => 1,
      isComplete: () => paymentIsCompleted,
    },
  ];


  useEffect(() => {
    // #1 - Managing URL params
    /* ********************************************************************* */
    const urlParamsOnPgLoad = new URLSearchParams(window.location.search);
    const paramCurrentStep = urlParamsOnPgLoad.get('currentStep');
    const paramTotalSteps = urlParamsOnPgLoad.get('totalSteps');
    const paramActiveWizard = urlParamsOnPgLoad.get('activeWizard');

    // #2 - Fetch backend Data
    /* ********************************************************************* */
    setShowDataLoadingOverlay(true);
    async function getUserData() {
      function integrateDbValsAndDefaults(dbVals, defaults) {
        return dbVals.reduce((accumulator, { key, val }) => {
          accumulator[key] = (val !== null) ? val : defaults[key];
          return accumulator;
        }, {});
      }

      let currentCompanyId = getUserInfoFromLocalStorage('companyId');
      if (!currentCompanyId) currentCompanyId = await getCompanyIdFromBackend();

      const userId = await getUserId();

      try {
        const [companyData, accountData] = await Promise.all([
          fetch(
            `${process.env.REACT_APP_BACKEND_URL}/companies/details/${currentCompanyId}&${userId}`,
            await createAuthHeaders('get', {}, true),
          ),
          fetch(
            `${process.env.REACT_APP_BACKEND_URL}/accounts/${currentCompanyId}&${userId}`,
            await createAuthHeaders('get', {}, true),
          ),
        ]);

        const [companyDataParsed, accountDataParsed] = await Promise.all([
          companyData.json(),
          accountData.json(),
        ]);

        const {
          accountingAdvisorEmail = null,
          accountingAdvisorName,
          accountingAdvisorPermission,
          accountingFirmName,
          // companyId,
          // companyName,
          companyStreetAddress,
          // companyType,
          companyWebsite,
          // createdDatetime,
          dba,
          ein,
          extraUsers,
          // insertUserId,
          legalAdvisorEmail,
          legalAdvisorName,
          legalAdvisorPermission,
          legalFirmName,
          // partnerFirm,
          // partnerType,
          // partnerUserId,
          // primaryUserId,
          // resource,
          city,
          state,
          // websiteUrl,
          zipCode,
          signature,
        } = companyDataParsed.Body;

        const {
          // accountId,
          // accountType,
          // cognitoUuid,
          // companyId,
          companyName,
          // createdDatetime,
          email,
          firstName,
          // isAuthSignatory,
          // isClient,
          lastName,
          // marketingOptIn,
          // partnerFirm,
          // partnerUserId,
          phoneNumber,
          // resource,
          // version,
        } = accountDataParsed.Body;

        const alreadyFilledOutData = [
          accountingAdvisorEmail,
          accountingAdvisorName,
          accountingAdvisorPermission,
          accountingFirmName,
          companyStreetAddress,
          companyWebsite,
          dba,
          ein,
          extraUsers,
          legalAdvisorEmail,
          legalAdvisorName,
          legalAdvisorPermission,
          legalFirmName,
          city,
          state,
          zipCode,
          signature,
          phoneNumber,
          lastName,
          firstName,
        ].some((retrievedData) => retrievedData !== null);

        setHasStarted(alreadyFilledOutData);

        // Wiz #1
        // Step - 1
        const userInfoDBVals = [
          { key: 'firstName', val: firstName },
          { key: 'lastName', val: lastName },
          { key: 'email', val: email },
          { key: 'phoneNumber', val: phoneNumber },
        ];

        const userInfoIntegratedVals = integrateDbValsAndDefaults(
          userInfoDBVals,
          userInfoDefaultValues,
        );

        setUserInfoInput(userInfoIntegratedVals);

        // Step - 2
        const companyInfoDBVals = [
          { key: 'companyName', val: companyName },
          { key: 'companyStreetAddress', val: companyStreetAddress },
          { key: 'city', val: city },
          { key: 'state', val: state },
          { key: 'zipCode', val: zipCode },
          { key: 'ein', val: ein },
          { key: 'dba', val: dba },
          { key: 'companyWebsite', val: companyWebsite },
          { key: 'extraUsers', val: extraUsers },
        ];

        const companyIntegratedVals = integrateDbValsAndDefaults(
          companyInfoDBVals,
          companyInfoDefaultValues,
        );

        setCompanyInfoInput(companyIntegratedVals);

        // Step - 3
        const legalFirmInfoDBVals = [
          { key: 'legalFirmName', val: legalFirmName },
          { key: 'legalAdvisorName', val: legalAdvisorName },
          { key: 'legalAdvisorEmail', val: legalAdvisorEmail },
          { key: 'legalAdvisorPermission', val: legalAdvisorPermission },
        ];

        const legalFirmIntegratedVals = integrateDbValsAndDefaults(
          legalFirmInfoDBVals,
          legalFirmInfoDefaultValues,
        );

        setLegalFirmInfoInput(legalFirmIntegratedVals);

        // Step - 4
        const accountingFirmInfoDBVals = [
          { key: 'accountingFirmName', val: accountingFirmName },
          { key: 'accountingAdvisorName', val: accountingAdvisorName },
          { key: 'accountingAdvisorEmail', val: accountingAdvisorEmail },
          { key: 'accountingAdvisorPermission', val: accountingAdvisorPermission },
        ];

        const accountingFirmIntegratedVals = integrateDbValsAndDefaults(
          accountingFirmInfoDBVals,
          accountingFirmInfoDefaultValues,
        );

        setAccountingFirmInfoInput(accountingFirmIntegratedVals);

        // Signature
        setUserSignature(signature);
      } catch (e) {
        setShowErrorMessage(e.toString());
      } finally {
        if (paramCurrentStep && paramTotalSteps && paramActiveWizard) {
          setWizSteps({
            currentStep: parseInt(paramCurrentStep, 10),
            totalSteps: parseInt(paramTotalSteps, 10),
          });
          setActiveWizard(parseInt(paramActiveWizard, 10));
          setInWiz(true);
        } else {
          setCheckForLastCompletedStep(true);
        }
        setShowDataLoadingOverlay(false);
      }
    }
    getUserData();
  }, []);

  useEffect(() => {
    if (checkForLastCompletedStep) {
      setActiveWizard(steps.findIndex(({ isComplete }) => !isComplete()));
    }
    setCheckForLastCompletedStep(false);
  }, [checkForLastCompletedStep]);

  useEffect(() => {
    const { firstName, lastName } = userInfoInput;
    if (firstName && lastName) {
      setGlobalFirstAndLastName({ firstName: toTitleCase(firstName), lastName: toTitleCase(lastName) });
      setUserInfoToLocalStorage('firstAndLastName', { firstName, lastName });
    } else setGlobalFirstAndLastName(null);
  }, [userInfoInput.firstName, userInfoInput.lastName]);

  useEffect(() => {
    if (inWiz) {
      const { currentStep, totalSteps } = wizSteps;
      nav(`?currentStep=${currentStep}&totalSteps=${totalSteps}&activeWizard=${activeWizard}`);
    }
  }, [wizSteps, inWiz, activeWizard]);

  async function updateUserData(isComplete) {
    const userId = await getUserId();
    let currentCompanyId = getUserInfoFromLocalStorage('companyId');
    if (!currentCompanyId) currentCompanyId = await getCompanyIdFromBackend();

    // TODO we will need some of these models edited so they take only partial data
    // because clientId and other data that does not need to be updated in the calls
    // should not have to be bundled in with them
    const accountUserData = {
      ...userInfoInput,
      cognitoUuid: userId,
      companyId: currentCompanyId,
      companyName: companyInfoInput.companyName,
      progress: isComplete ? 'completed' : 'not completed',
    };
    const companyData = {
      companyId: currentCompanyId,
      insertUserId: userId,
      ...companyInfoInput,
      ...legalFirmInfoInput,
      ...accountingFirmInfoInput,
      signature: userSignature,
      progress: isComplete ? 'completed' : 'not completed',
    };

    const [acctRequestOptions, companyRequestOptions] = await Promise.all([
      createAuthHeaders('post', accountUserData, true),
      createAuthHeaders('post', companyData, true),
    ]);

    async function updateAllUserData(accountUpdateData, companyUpdateData) {
      await Promise.all([
        fetch(`${process.env.REACT_APP_BACKEND_URL}/accounts/create-account?access_token=${process.env.REACT_APP_BACKEND_API_KEY}`, accountUpdateData),
        fetch(`${process.env.REACT_APP_BACKEND_URL}/companies/create-company`, companyUpdateData),
      ]);
    }

    try { // Writing new data to DB
      await updateAllUserData(acctRequestOptions, companyRequestOptions);
    } catch (e) {
      setShowErrorMessage(e.toString());
    }
  }

  useEffect(() => {
    async function sendConfirmationEmail() {
      const oneYearFromNowFormattedDate =
        new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toLocaleDateString('en-us', {
          weekday: 'long',
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        });
      await sendHtmlEmail(
        userInfoInput.email,
        'accountCreationSuccess',
        [
          `${toTitleCase(userInfoInput.firstName)} ${toTitleCase(userInfoInput.lastName)}`,
          companyInfoInput.companyName,
          oneYearFromNowFormattedDate,
        ],
      );
    }
    if (paymentIsCompleted) {
      updateUserData(true);
      sendConfirmationEmail();
    }
  }, [paymentIsCompleted]);


  function determineIcon(isWizardComplete, index) {
    const isCurrentWizComplete = isWizardComplete();

    let status = 'not-started';
    if (isCurrentWizComplete) status = 'completed';
    else if (activeWizard === index) status = 'is-next';
    else if (stepsVisited.includes(index)) status = 'has-started';

    return {
      icon: isCurrentWizComplete && <DoneIcon />,
      status,
    };
  }

  function openNewWizard(
    activeWizIndex,
    numTotalSteps,
    startOnThisStep = 1,
    isInTheWiz = true,
  ) {
    setInWiz(isInTheWiz);
    setActiveWizard(activeWizIndex);
    wizSteps.totalSteps = numTotalSteps;
    wizSteps.currentStep = startOnThisStep;
    setWizSteps(copy(wizSteps));
  }

  function isInReviewAndSignFirstStep() {
    return activeWizard === 1 && wizSteps.currentStep === 1;
  }

  function isInReviewAndSignSecondStep() {
    return activeWizard === 1 && wizSteps.currentStep === 2;
  }

  function isInMakePaymentFinalWizard() {
    return activeWizard === 2;
  }

  function home() {
    return (
      <>
        {
          showDataLoadingOverlay && (
            <div className="loading-wrapper">
              <LoadingSpinner className="custom-loading-spinner" />
            </div>
          )
        }
        <h4>Finish your account setup</h4>
        <img src={helloAvatar} className="hello-avatar-image" alt="hello and welcome to customer input form" />
        <p>
          Finish setting up your account in just three easy steps that take an average of
          5-10 minutes to complete.
        </p>
        <Box sx={{ maxWidth: 400 }}>
          <Stepper activeStep={activeWizard} orientation="vertical">
            {
              steps.map(({ label, totalSteps, isComplete }, i, rootArr) => {
                const { icon, status } = determineIcon(isComplete, i, rootArr);

                return (
                  <Step
                    key={label}
                    disabled={false}
                    // eslint-disable-next-line max-len
                    className={`individual-step ${(!userSignature && label === 'Make payment') ? 'no-click-event' : ''}`}
                    onClick={(e) => {
                      e.preventDefault();
                      if (!hasStarted) setHasStarted(true);
                      openNewWizard(i, totalSteps());
                    }}
                  >
                    <StepLabel
                      className={status}
                      StepIconComponent={() => icon || i + 1}
                    >
                      {label}
                      {/completed/ig.test(status) && <CreateOutlinedIcon />}
                    </StepLabel>
                    {label === 'Make payment' && <ArrowTooltip />}
                  </Step>
                );
              })
            }
          </Stepper>
        </Box>
        <Button
          variant="contained"
          className="start-btn"
          onClick={() => {
            if (!hasStarted) setHasStarted(true);
            const { totalSteps } = steps[activeWizard];
            openNewWizard(activeWizard, totalSteps());
          }}
        >
          {hasStarted ? 'Next' : 'Finish setup'}
        </Button>
      </>
    );
  }

  function wiz() {
    if (activeWizard === 0) {
      return (
        <Wiz1
          // TODO condense all data into one object Wiz1Data
          currentStep={wizSteps.currentStep}
          userInfoInput={userInfoInput}
          companyInfoInput={companyInfoInput}
          legalFirmInfoInput={legalFirmInfoInput}
          accountingFirmInfoInput={accountingFirmInfoInput}
          inputHasError={inputHasError}
          setUserInfoInput={setUserInfoInput}
          setCompanyInfoInput={setCompanyInfoInput}
          setLegalFirmInfoInput={setLegalFirmInfoInput}
          setAccountingFirmInfoInput={setAccountingFirmInfoInput}
          setInputHasError={setInputHasError}
        />
      );
    }
    if (activeWizard === 1) {
      if (!reviewPageHasBeenVisited) {
        setReviewPageHasBeenVisited(true);
      }
      return (
        <ReviewAndSign
          openNewWizard={openNewWizard}
          steps={steps}
          // TODO only pass wizSteps
          currentStep={wizSteps.currentStep}
          wizSteps={wizSteps}
          setWizSteps={setWizSteps}
          isFormCompleted={isFormCompleted()}
          userInfoInput={userInfoInput}
          companyInfoInput={companyInfoInput}
          legalFirmInfoInput={legalFirmInfoInput}
          accountingFirmInfoInput={accountingFirmInfoInput}
          inputHasError={inputHasError}
          showErrors={showErrors}
          setShowErrors={setShowErrors}
          userSignature={userSignature}
          setUserSignature={setUserSignature}
        />
      );
    }
    if (activeWizard === 2) {
      return (
        <MakePayment
          makePaymentButtonRef={makePaymentButtonRef}
          setPaymentFormIsLoading={setPaymentFormIsLoading}
          setPaymentIsProcessing={setPaymentIsProcessing}
          setPaymentIsCompleted={setPaymentIsCompleted}
          discountPaymentData={discountPaymentData}
          setDiscountPaymentData={setDiscountPaymentData}
        />
      );
    }

    return <h2>Sorry, not a valid step...</h2>;
  }

  if (paymentIsCompleted) {
    return (
      <div className="ClientIntakeForm success-container">
        <h2>Success!</h2>
        <div className="success-msg-container">
          <CheckCircleIcon />
          <p>
            {`You've successfully set up your ${companyInfoInput.companyName}'s account. We've emailed you a confirmation of payment.`}
          </p>
        </div>
        <Button
          className="payment-page-link"
          onClick={() => {
            nav('/');
            setReloadHomepage(true);
          }}
        >
          Go to homepage
        </Button>
        <Button
          className="payment-page-link"
          onClick={async () => {
            setIsSigningOut(true);
            await Auth.signOut();
            setIsAuthenticated(false);
            nav('/login');
            setIsSigningOut(false);
          }}
        >
          Sign out
        </Button>
      </div>
    );
  }

  function nextOrMakePaymentBtnText() {
    if (isInReviewAndSignFirstStep()) return 'Submit information';

    if (isInMakePaymentFinalWizard()) {
      if (paymentIsProcessing) {
        return (
          <>
            <span className="dots-circle-spinner" />
            Processing Payment
          </>
        );
      }

      return discountPaymentData.chargeAmount === '0' ? 'Proceed without payment' : 'Make payment';
    }

    return 'Next';
  }

  return (
    <div className="ClientIntakeForm">

      {inWiz && (
        <div className="bread-crumb">
          <button
            type="button"
            className="bread-crumb-btn"
            onClick={() => {
              setInWiz(false);
              setCheckForLastCompletedStep(true);
              nav('/');
            }}
          >
            <span className="top-navigation">
              <ArrowBackIcon className="back-icon" />
              <span>Account setup</span>
            </span>
          </button>
          <span>{steps[activeWizard]?.label}</span>
        </div>
      )}

      <div className="client-form-container">
        {
          inWiz && (
            <ProgressIndicatorLine
              stepUserIsCurrentlyOn={wizSteps.currentStep}
              totalSteps={wizSteps.totalSteps}
              customStyles={{ marginBottom: '24px' }}
            />
          )
        }
        <div className="client-form" style={!inWiz ? { padding: '40px 140px 40px 140px' } : {}}>
          {inWiz ? wiz() : home()}
        </div>
        {
          inWiz && (
            <div className="bottom-btn-container">
              {
                (
                  reviewPageHasBeenVisited &&
                  (activeWizard === 0)
                ) && (
                  <Button
                    variant="text"
                    className="back-to-review"
                    onClick={(e) => {
                      e.preventDefault();
                      openNewWizard(1, steps[1].totalSteps(), 1);
                      updateUserData();
                    }}
                  >
                    Back to review page
                  </Button>
                )
              }
              <Button
                variant="outlined"
                className="back-btn"
                onClick={(e) => {
                  e.preventDefault();
                  if (activeWizard === 0) {
                    updateUserData();
                  }
                  if (wizSteps.currentStep === 1) {
                    setInWiz(false);
                    setCheckForLastCompletedStep(true);
                    nav('/');
                  } else {
                    wizSteps.currentStep -= 1;
                    setWizSteps(copy(wizSteps));
                  }
                }}
              >
                Back
              </Button>
              <Button
                variant="contained"
                className="next-btn"
                disabled={(
                  (isInReviewAndSignSecondStep() && !userSignature) ||
                  paymentFormIsLoading || paymentIsProcessing
                )}
                onClick={(e) => {
                  e.preventDefault();

                  if (activeWizard === 0 || activeWizard === 1) {
                    updateUserData();
                  }
                  if (
                    isInReviewAndSignFirstStep() &&
                    !isFormCompleted()
                  ) {
                    setShowErrors(true);
                    return;
                  }

                  if (isInMakePaymentFinalWizard()) {
                    makePaymentButtonRef.current.click();
                    return;
                  }

                  if (wizSteps.currentStep === wizSteps.totalSteps) {
                    let nextActiveWiz = activeWizard + 1;
                    if (!steps[nextActiveWiz]) nextActiveWiz = steps.length - 1;
                    const newStepsVisitedArray = copy(stepsVisited);
                    newStepsVisitedArray.push(activeWizard);
                    setStepsVisited(newStepsVisitedArray);
                    setActiveWizard(nextActiveWiz);
                    setInWiz(false);
                    wizSteps.currentStep = 1;
                    setWizSteps(copy(wizSteps));
                    nav('/');
                  } else {
                    wizSteps.currentStep += 1;
                    setWizSteps(copy(wizSteps));
                  }
                }}
              >
                {nextOrMakePaymentBtnText()}
              </Button>
            </div>
          )
        }
      </div>
    </div>
  );
}
