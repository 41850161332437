import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import Button from '@mui/material/Button';

import { timeOfDayGreeting, createAuthHeaders, getUserInfoFromLocalStorage, getCompanyIdFromBackend } from '../../utils';

import { ReactComponent as WaitingGraphic } from '../../images/WaitingGraphic.svg';
import { ReactComponent as SandboxReadyGraphic } from '../../images/SandboxReadyGraphic.svg';

import ValReport from './ValReport';
import TransactionInfo from './TransactionInfo';

import './index.scss';

export default function FinalHomePage({
  userFirstAndLastName,
  currentUserState,
  reportIsReady,
  userTransactions,
}) {
  const nav = useNavigate();

  const [viewReportPage, setViewReportPage] = useState(false);

  const [viewPreviousTransaction, setViewPreviousTransaction] = useState(false);

  if (viewReportPage) return <ValReport setViewReportPage={setViewReportPage} reportIsReady={reportIsReady} />;

  async function startNew409A() {
    await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/transactions/new-transaction/${getUserInfoFromLocalStorage('companyId') || await getCompanyIdFromBackend()}`,
      await createAuthHeaders('post', {}, true),
    );
    nav(`/409a-info`);
  }

  if (viewPreviousTransaction) return (
    <TransactionInfo
      viewPreviousTransaction={viewPreviousTransaction}
      setViewPreviousTransaction={setViewPreviousTransaction}
      hasOldSandbox={viewPreviousTransaction.hasOldSandbox}
      reportIsReady
    />
  );

  return (
    <div className="FinalHomePage">
      <div className="final-homepage-header">
        <h4>
          Good
          {' '}
          {timeOfDayGreeting()}
          {', '}
          <span className="user-name">
            {`${userFirstAndLastName?.firstName || ''} ${userFirstAndLastName?.lastName || ''}`}
          </span>
        </h4>
        {reportIsReady && <Button onClick={startNew409A}> Start new 409A</Button>}
        {(currentUserState === '409A-NotStarted' || currentUserState === '409A-InProgress') && (
          <Button onClick={() => nav('/409a-info')}>Resume 409A</Button>
        )}
      </div>
      <div className="content-wrapper">
        <h6>Most recent 409A valuation</h6>
        {currentUserState === '409A-InProgress' && (
          <p>
            You&apos;ve started a new 409A valuation but have yet to finish it.
            We can&apos;t get started on your valuation until you submit your information and make payment (if applicable).&nbsp;
            <Button onClick={() => nav('/409a-info')}>
              Resume now
            </Button>
          </p>
        )}
        {(currentUserState === 'Sandbox-Finalized' || currentUserState === 'HasReport') && (
          <p>
            Below you will find all artifacts related to your 409A valuation.
            You can view everything and download or print your final report at any time.
          </p>
        )}
        <div className="sandbox-report-review-wrapper">
          {(currentUserState === 'Sandbox-Open' || currentUserState === '409A-Completed') && (
            <div className="info-card">
              {currentUserState === '409A-Completed' ? (
                <>
                  <WaitingGraphic className="splash-graphic" />
                  <div className="right-side-text">
                    <p className="title">
                      Sit back and relax. We&apos;re reviewing your information.
                    </p>
                    <p>
                      We&apos;ll email you within the next 7 business days when your 409A valuation sandbox is ready.
                    </p>
                    <Button onClick={() => nav('/409a-info?review=true')}>
                      View submitted info
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <SandboxReadyGraphic className="splash-graphic" />
                  <div className="right-side-text">
                    <p className="title">
                      Check it out. You&apos;re 409A valuation sandbox is ready.
                    </p>
                    <p>
                      The sandbox is where you can, in real-time, customize your 409A before you lock in your final valuation.
                    </p>
                    <Button onClick={() => nav('/409a-price-sandbox')}>
                      Got to 409A sandbox
                    </Button>
                  </div>
                </>
              )}
            </div>
          )}
          {(currentUserState === 'Sandbox-Finalized' || currentUserState === 'HasReport') && (
            <>
              <div className="TransactionInfoBlock item">
                <div
                  role="button"
                  tabIndex={0}
                  aria-label="View report"
                  className="overlay"
                  onClick={() => setViewReportPage(true)}
                  onKeyDown={(e) => { if (e.key === 'Enter') setViewReportPage(true); }}
                />
                <div className="title-background-image report-homepage-thumb" />
                <div className="transaction-content">
                  <div className="left-side">
                    <span className="final-info title">
                      409A valuation report
                    </span>
                    {reportIsReady ? (
                      <div className="in-progress">
                        Completed
                      </div>
                    ) : (
                      <div className="in-progress">
                        In Progress
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="TransactionInfoBlock item">
                <div
                  role="button"
                  tabIndex={0}
                  aria-label="Open sandbox"
                  className="overlay"
                  onClick={() => nav('/409a-price-sandbox')}
                  onKeyDown={(e) => { if (e.key === 'Enter') nav('/409a-price-sandbox'); }}
                />
                <div className="title-background-image sandbox-homepage-thumb" />
                <div className="transaction-content">
                  <div className="left-side">
                    <span className="final-info title">
                      409A sandbox
                    </span>
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="TransactionInfoBlock item">
            <div
              role="button"
              tabIndex={0}
              aria-label="View 409A valuation form"
              className="overlay"
              onClick={() => nav(`/409a-info${(currentUserState === '409A-NotStarted' || currentUserState === '409A-InProgress') ? '' : '?review=true'}`)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  nav(`/409a-info${(currentUserState === '409A-NotStarted' || currentUserState === '409A-InProgress') ? '' : '?review=true'}`);
                }
              }}
            />
            <div className="title-background-image submission-homepage-thumb" />
            <div className="transaction-content">
              <div className="left-side">
                <p className="final-info title">
                  409A valuation form
                </p>
                {currentUserState === '409A-InProgress' && (
                  <p className="final-info text">
                    In progress, click to resume
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="previous-transactions">
        <h5>Completed 409A valuations</h5>
        {userTransactions.map((transaction, index) => {
          if (index !== 0) return (
            <div
              key={transaction.transactionId}
              className="previous-transaction-card"
              role="button"
              tabIndex={0}
              aria-label="Open previous transaction"
              onClick={() => setViewPreviousTransaction(transaction)}
              onKeyDown={(e) => { if (e.key === 'Enter') setViewPreviousTransaction(transaction); }}
            >
              <div className="val-type">409A</div>
              <p>{`${transaction.companyName.toUpperCase()} 409A`}</p>
              <div className="right-side">
                <p>{`Last round: ${transaction.chosenFunding}`}</p>
                <p>{`Valuation date: ${moment(transaction.valuationDate, 'YYY-MM-DD').format('MM/DD/YYYY')}`}</p>
              </div>
            </div>
          );
          return null;
        })}
      </div>
    </div>
  );
}

FinalHomePage.propTypes = {
  userFirstAndLastName: PropTypes.object,
  reportIsReady: PropTypes.bool,
  userTransactions: PropTypes.array,
  currentUserState: PropTypes.string,
};
