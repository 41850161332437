import React, {
  useState,
  useRef,
  useEffect,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import { Storage, Auth } from 'aws-amplify';

import IconButton from '@mui/material/IconButton';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import Tooltip from '@mui/material/Tooltip';

import { ReactComponent as ExcelIcon } from '../../images/icons/File_Type_Excel.svg';
import { ReactComponent as PowerPointIcon } from '../../images/icons/File_Type_Powerpoint.svg';
import { ReactComponent as WordIcon } from '../../images/icons/File_Type_Word.svg';
import { ReactComponent as PdfIcon } from '../../images/icons/File_Type_Pdf.svg';
import { ReactComponent as AltDocIcon } from '../../images/icons/File_Type_Alt.svg';
import { ReactComponent as LoadingSpinner } from '../../images/loading-spinner.svg';
import { copy, createAuthHeaders } from '../../utils';
import checkWithUnderline from '../../images/icons/check_with_underline.svg';
import { ErrorMessageContext } from '../../lib/contextLib';
import './FileUploadAndInfoContainer.scss';

function handleFileDragOnWindow(e) {
  e.preventDefault();
}

export default function FileUploadAndInfoContainer({
  customContainerStyles,
  filesUploadedParent,
  setFilesUploadedParent,
}) {
  const { setShowErrorMessage } = useContext(ErrorMessageContext);
  const [fileIsDraggingOver, setFileIsDraggingOver] = useState(false);
  const [filesUploaded, setFilesUploaded] = useState([]);
  const [clientCompanyId, setClientCompanyId] = useState('');
  const [showDataLoadingOverlay, setShowDataLoadingOverlay] = useState(false);
  const [fileValue, setFileValue] = useState('');
  const fileUploadElement = useRef(null);

  async function getCompanyId() {
    const currentSessionUser = await Auth.currentSession();
    const userId = currentSessionUser.accessToken.payload.sub;
    const requestOptions = await createAuthHeaders('get', {}, true);
    try {
      const userInfo = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/accounts/companies/${userId}`,
        requestOptions,
      );
      const userInfoResult = await userInfo.json();
      const { companyId } = userInfoResult.Body;
      setClientCompanyId(companyId);
    } catch (e) {
      setShowErrorMessage(e.toString());
    }
  }

  useEffect(() => {
    setFilesUploaded(filesUploadedParent || []);
    getCompanyId();

    // TODO make this a hell of a lot more elegant
    // https://www.freecodecamp.org/news/reactjs-implement-drag-and-drop-feature-without-using-external-libraries-ad8994429f1a/
    window.addEventListener('dragover', handleFileDragOnWindow, false);
    window.addEventListener('drop', handleFileDragOnWindow, false);
    return () => {
      window.removeEventListener('dragover', handleFileDragOnWindow);
      window.removeEventListener('drop', handleFileDragOnWindow);
    };
  }, []);

  useEffect(() => {
    setFilesUploadedParent(filesUploaded || []);
  }, [filesUploaded]);

  async function uploadFile(fileName, file) {
    const dateTime = Date.now();
    await Storage.put(`${clientCompanyId}/${dateTime}-${fileName}`, file);
    setFilesUploaded(
      copy(filesUploaded.concat({
        fileName,
        dateTime,
      })),
    );
  }

  async function handleFileUpload(event, fileToUpload) {
    event.preventDefault();
    if (!fileToUpload) return;
    if (fileToUpload.size >= 35000000) {
      alert('File size is too big');
      setFileIsDraggingOver(false);
      return;
    }
    // TODO Handle different file types here...
    setShowDataLoadingOverlay(true);
    try {
      await uploadFile(fileToUpload.name, fileToUpload);
    } catch (e) {
      setShowErrorMessage(e.toString());
    } finally {
      setFileIsDraggingOver(false);
      setShowDataLoadingOverlay(false);
    }
  }

  async function removeFile(file, fileIndex) {
    setShowDataLoadingOverlay(true);
    await Storage.remove(`${clientCompanyId}/${file.dateTime}-${file.fileName}`);
    const newFileList = copy(filesUploaded);
    newFileList.splice(fileIndex, 1);
    setFilesUploaded(newFileList);
    setShowDataLoadingOverlay(false);
  }

  return (
    <div
      className="file-upload-and-info-container"
      style={customContainerStyles}
    >
      <div
        className={`drag-and-drop-area ${fileIsDraggingOver ? 'file-dragging' : ''}`}
        onDrop={(e) => handleFileUpload(e, e.dataTransfer.files[0])}
        onDragEnter={(e) => {
          e.preventDefault();
          setFileIsDraggingOver(true);
        }}
        onDragExit={(e) => {
          e.preventDefault();
          setFileIsDraggingOver(false);
        }}
      >
        {
          showDataLoadingOverlay && (
            <div className="file-upload-loading-wrapper">
              <LoadingSpinner className="custom-loading-spinner" />
            </div>
          )
        }
        <div className="browse-or-drop-content">
          <UploadFileIcon sx={{ width: '26px', height: '33px', color: '#49454F' }} />
          <div>
            <div className="browse-or-drop-text">
              <span>
                <button
                  className="browse-files-btn"
                  type="button"
                  onClick={() => {
                    setFileValue('');
                    fileUploadElement.current.click();
                  }}
                >
                  Browse
                </button>
                {' '}
                or drop files here
              </span>
              <span className="max-file-size">
                Max file size 35 MB
              </span>
            </div>
          </div>
        </div>
        <Tooltip
          title={(
            <>
              <p>Accepted file types:</p>
              <p>
                <span>Excel&nbsp;</span>
                (.xls, .xlsx, .xlsm),&nbsp;
                <span>Word&nbsp;</span>
                (.doc, .docx),&nbsp;
              </p>
              <p>
                <span>PDF, Powerpoint&nbsp;</span>
                (.ppt, .pptx, .pps, .ppsx),&nbsp;
              </p>
              <p>
                <span>Image files&nbsp;</span>
                (.jpg, .jpeg, .heic, .png)&nbsp;
              </p>
            </>
          )}
          PopperProps={{ className: 'file-type-tooltip' }}
          placement="bottom"
          arrow
        >
          <span className="file-type-icons">
            Accepted file types
            <ExcelIcon className="icon" />
            <PdfIcon className="icon" />
            <WordIcon className="icon" />
            <PowerPointIcon className="icon" />
            <AltDocIcon className="icon" />
          </span>
        </Tooltip>
        <input
          ref={fileUploadElement}
          type="file"
          accept=".xlsx,.xls,.xlsm,.doc,.docx,.ppt,.pptx,.pps,.ppsx,.txt,.pdf,.csv,.jpg,.jpeg,.heic,.png"
          className="file-style"
          value={fileValue}
          onChange={(e) => {
            e.preventDefault();
            handleFileUpload(e, e.target.files[0]);
          }}
        />
      </div>
      <div className="uploaded-files-display">
        {
          filesUploaded.map((file, fileIndex) => {
            return (
              <div
                className="file"
                key={`${file.fileName.replaceAll(' ', '++--++')}-${file.dateTime}`}
              >
                <div>
                  <div className="file-name-and-checkmark-row">
                    <div>
                      <img
                        src={checkWithUnderline}
                        className="checkmark-with-underline"
                        alt="Checkmark with underline"
                      />
                      <span>{(file.fileName.length >= 30) ? `${file.fileName.slice(0, 30).trim()}...` : file.fileName}</span>
                    </div>
                  </div>
                  <span className="upload-complete">Upload complete</span>
                </div>
                <IconButton
                  className="clear-icon"
                  onClick={() => removeFile(file, fileIndex)}
                >
                  <DeleteOutlineIcon />
                </IconButton>
              </div>
            );
          })
        }
      </div>
    </div>
  );
}

FileUploadAndInfoContainer.propTypes = {
  customContainerStyles: PropTypes.object,
  filesUploadedParent: PropTypes.arrayOf(PropTypes.object),
  setFilesUploadedParent: PropTypes.func.isRequired,
};
