import { Auth } from 'aws-amplify';
import cloneDeep from 'lodash.clonedeep';
import { numericFormatter } from 'react-number-format';

export function toTitleCase(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function copy(object) {
  return cloneDeep(object);
}

// anystring@anystring.anystring
// Prevents matching multiple @ signs
export function isAValidEmail(str) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(str);
}
// check for valid (xxx) xxx-xxxx phone US phone number format
export function isAValidUSPhoneNumber(str) {
  return /^\(?([0-9]{3})\)?[ ]?([0-9]{3})[-]?([0-9]{4})$/.test(str);
}
// check for valid xx-xxxxxxx EIN number format
export function isAValidEINNumber(str) {
  return /^\(?([0-9]{2})[-]?([0-9]{7})$/.test(str);
}
// check for valid US Zip Code format
export function isAValidZipCode(str) {
  return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(str);
}

export function formatPhoneNumber(value) {
  // clean the input for any non-digit values.
  const phoneNumber = value.replace(/[^\d]/g, '');

  // we need to return the value with no formatting if its less than four digits
  // this is to avoid weird behavior that occurs if you  format the area code too early
  if (phoneNumber.length < 4) return phoneNumber;

  // if phoneNumber.length is greater than 4 and less the 7 we start to return
  // the formatted number
  if (phoneNumber.length < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }

  // finally, if the phoneNumber.length is greater then seven, we add the last
  // bit of formatting and return it.
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
}

export function formatEINNumber(value) {
  // clean the input for any non-digit values.
  const EINNumber = value.replace(/[^\d]/g, '');

  // if  EIN Number > 3 characters, we can start formatting with added hyphen
  if (EINNumber.length > 3) {
    return `${EINNumber.slice(0, 2)}-${EINNumber.slice(2, 9)}`;
  }

  // if the EIN Number is < 3 characters, no need to format it yet, we can simply return it
  return EINNumber;
}

export function formatZipCode(value) {
  // only allows numbers and hyphen as input
  const ValidZipCode = value.replace(/[^\d|^-]/g, '');
  return ValidZipCode;
}

export const formatUSCurrency = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumSignificantDigits: 2,
});

export const formatUSCurrencyWithTwoDecimals = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

export const formatUSCurrencyWithFourDecimals = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 4,
  minimumFractionDigits: 4,
});

export const onlyNums = (str) => str.replace(/[^\d.-]/g, '');

export const commaEvery3rdChar = (val) => val.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');

export function formatNumericValue(val, { thousandSeparator = true, allowNegative = false, allowLeadingZeros = false, decimalScale = 4 } = {}) {
  let formattedVal = (val || '').toString();
  if (allowNegative) {
    formattedVal = formattedVal.replace(/[^\d.-]/g, '');
    formattedVal = formattedVal.replace(/(?<=[\s\S]+)-/g, '');
  } else {
    formattedVal = formattedVal.replace(/[^\d.]/g, '');
  }
  return numericFormatter(formattedVal, { thousandSeparator, allowNegative, allowLeadingZeros, decimalScale });
}

// limit the number of characters and numerical value of a given string
export function maxCharsAndValue(str, charLimit, valLimit) {
  let newStr = str;
  if (parseInt(newStr, 10) > valLimit) {
    newStr = valLimit.toString();
  }
  if (newStr.length > charLimit) {
    newStr.substring(0, charLimit);
  }
  return newStr;
}

export function formatInputToNumWith2Decimals(value) {
  let dollars;
  let cents;

  const nums = value.split('.').map((s) => s.replace(/\D/ig, ''));

  if (nums.length >= 2) {
    ([dollars] = nums);
    nums.shift();
    cents = nums.join('');
    if (cents.length >= 3) cents = cents.slice(0, 2);
  } else {
    dollars = nums.join('');
    cents = null;
  }

  return `${commaEvery3rdChar(dollars)}${cents !== null ? `.${cents}` : ''}`;
}

// adds a given character to a string (%, x, yrs...)
export function concatCharacter(input, char) {
  return input && (!input.toString().includes(char) ? input.toString() + char : input);
}

// Works whether number has , or not
export function doesNumHaveLeading0s(num) {
  return /^0+/.test(num) && /[1-9]/ig.test(num);
}

export function removeCommas(s) {
  return s.replaceAll(',', '');
}

export function trimLeading0s(num) {
  return num.replaceAll(/^0+/g, '');
}

export function camelize(str) {
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
    return index === 0 ? word.toLowerCase() : word.toUpperCase();
  }).replace(/\s+/g, '');
}

export function timeOfDayGreeting() {
  const today = new Date();
  const curHr = today.getHours();

  if (curHr < 12) return 'morning';
  if (curHr < 18) return 'afternoon';

  return 'evening';
}

export async function createAuthHeaders(
  method = 'get',
  body = {},
  isAuthorized = false,
) {
  const applyBody = Object.keys(body).length > 0;
  const payloadForFetch = {
    method,
    headers: {
      'Content-Type': 'application/json',
    },
    ...(applyBody && { body: JSON.stringify(body) }),
  };

  if (isAuthorized) {
    let JWT = '';
    const currentUserSession = await Auth.currentSession();
    JWT = currentUserSession.getIdToken().getJwtToken();
    // ALSO currentUserSession.getAccessToken().getJwtToken()
    payloadForFetch.headers.Authorization = `Bearer ${JWT}`;
  }

  return payloadForFetch;
}

export async function getUserId() {
  const authInfo = await Auth.currentAuthenticatedUser();
  return authInfo.attributes.sub;
}

export async function getUserEmail() {
  const authInfo = await Auth.currentAuthenticatedUser();
  return authInfo.attributes.email;
}

export async function grabCompanyIdAndName() {
  const userId = await getUserId();

  const companyInfo = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/accounts/companies/${userId}`,
    await createAuthHeaders('get', {}, true),
  );

  const { companyId, companyName } = (await companyInfo.json()).Body;

  return {
    companyId,
    companyName,
  };
}

export async function getCurrentAuthorizedUserId() {
  const currentSessionUser = await Auth.currentSession();
  const { sub } = currentSessionUser.accessToken.payload;
  const isAuthorized = currentSessionUser.getIdToken().payload.email_verified;

  return {
    userId: sub,
    isAuthorized,
  };
}

export async function getDBTransactionData(companyID, transactionID, givenUserId) {
  let userId;
  if (!givenUserId) userId = await getUserId();
  else userId = givenUserId;
  const transactionData = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/transactions/details/${companyID}&${transactionID}&${userId}`,
    await createAuthHeaders('get', {}, true),
  );
  const transactionDataParsed = await transactionData.json();

  if (
    transactionDataParsed.Body &&
    Array.isArray(transactionDataParsed.Body) &&
    transactionDataParsed.Body.length > 0
  ) {
    return transactionDataParsed.Body[0];
  }

  return null;
}

export async function getAllDBTransactionData(companyID, givenUserId) {
  let userId;
  if (!givenUserId) userId = await getUserId();
  else userId = givenUserId;
  const transactionData = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/transactions/get-all-transactions/${companyID}&${userId}`,
    await createAuthHeaders('get', {}, true),
  );
  const transactionDataParsed = await transactionData.json();
  if (
    transactionDataParsed.Body &&
    Array.isArray(transactionDataParsed.Body) &&
    transactionDataParsed.Body.length > 0
  ) {
    return transactionDataParsed.Body;
  }

  return null;
}

const localStorageInitioUserInfoKey = 'initioUserInfo';

async function getInitioInfoKey(optionalAccountId = null) {
  if (typeof optionalAccountId === 'string') {
    return `${localStorageInitioUserInfoKey}+${optionalAccountId}`;
  }

  const amplifyCognitoUuid = await getUserId();

  return `${localStorageInitioUserInfoKey}+${amplifyCognitoUuid}`;
}

export function getUserInfoFromLocalStorage(desiredAttr = null) {
  const initioUserLocalStorageInfoKey = getInitioInfoKey();
  let userInfo = localStorage.getItem(initioUserLocalStorageInfoKey);

  if (userInfo !== null) {
    userInfo = JSON.parse(userInfo);
    if (typeof desiredAttr === 'string' && typeof userInfo === 'object') {
      return userInfo[desiredAttr] || null;
    }
    return null;
  }

  return null;
}

export function setUserInfoToLocalStorage(
  desiredAttrKey,
  desiredAttrVal = null,
  optionalAccountId = null,
) {
  const initioKey = getInitioInfoKey(optionalAccountId);
  let userInfo = localStorage.getItem(initioKey);

  const setLocalVals = () => {
    localStorage.setItem(initioKey, JSON.stringify({
      [desiredAttrKey]: desiredAttrVal,
    }));
  };

  if ((userInfo !== null) && (typeof desiredAttrKey === 'string')) {
    userInfo = JSON.parse(userInfo);
    userInfo[desiredAttrKey] = desiredAttrVal;
    localStorage.setItem(initioKey, JSON.stringify(userInfo));
    setLocalVals();
  } else {
    setLocalVals();
  }

  return userInfo;
}

export function removeUserInfoFromLocalStorage(desiredAttrKey) {
  const initioInfoKey = getInitioInfoKey();
  let initioInfo = localStorage.getItem(initioInfoKey);

  if (initioInfo === null) {
    return initioInfo;
  }

  initioInfo = JSON.parse(initioInfo);
  delete initioInfo[desiredAttrKey];
  localStorage.setItem(getInitioInfoKey(), initioInfo);

  return initioInfo;
}

export function removeAllInitioUserInfo() {
  return localStorage.removeItem(getInitioInfoKey());
}

export function removeAllInitioUserInfoSignedOutUsers() {
  return localStorage.removeItem(`${localStorageInitioUserInfoKey}+false`);
}

export function ifValInLocalStorageSetVal(key, setter) {
  const userVal = getUserInfoFromLocalStorage(key);

  if (userVal) {
    if (userVal === 'true') {
      setter(true);
    } else if (userVal === 'false') {
      setter(false);
    } else {
      setter(userVal);
    }
  }

  return userVal;
}

export async function getCompanyIdFromBackend() {
  try {
    const { companyId } = await grabCompanyIdAndName();
    setUserInfoToLocalStorage('companyId', companyId);
    return companyId;
  } catch {
    return null;
  }
}

export async function getHomepageDataFromDB(companyId) {
  const userId = await getUserId();
  const homepageDBData = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/homepage/client/${companyId}&${userId}`,
    await createAuthHeaders('get', {}, true),
  );
  const homepageDBResponse = await homepageDBData.json();
  return homepageDBResponse;
}

export async function getClientStatus(companyId) {
  const userId = await getUserId();
  const homepageDBData = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/homepage/client/${companyId}&${userId}`,
    await createAuthHeaders('get', {}, true),
  );
  const homepageDBResponse = await homepageDBData.json();
  let homepageState;
  if (homepageDBResponse.userTransactions) {
    homepageState = homepageDBResponse.userTransactions[0].transactionStatus;
  } else ({ homepageState } = homepageDBResponse);
  return homepageState;
}

export async function getTransactionIdFromBackend(companyId) {
  // TODO What if we have several transactions to look for?
  try {
    const userId = await getUserId();
    const companyTransactionData = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/transactions/associated/${companyId}&${userId}`,
      await createAuthHeaders('get', {}, true),
    );
    const companyTransactionDataParsed = await companyTransactionData.json();
    if (
      companyTransactionDataParsed?.Body &&
      Array.isArray(companyTransactionDataParsed?.Body) &&
      companyTransactionDataParsed?.Body.length > 0
    ) {
      const { transactionId } = companyTransactionDataParsed.Body[0];
      return transactionId;
    }
    return null;
  } catch {
    return null;
  }
}

export async function sendHtmlEmail(recipient, templateName, templateData, metadata = {}) {
  const emailData = {
    recipient,
    templateName,
    templateData,
    metadata,
  };
  await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/emails/send-html-email`,
    await createAuthHeaders('post', emailData, true),
  );
}

export async function sendHtmlEmailWithAttachment(recipient, templateName, templateData, attachmentName, attachmentLink) {
  const emailData = {
    recipient,
    templateName,
    templateData,
    attachmentName,
    attachmentLink,
  };
  await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/emails/send-html-email-with-attachment`,
    await createAuthHeaders('post', emailData, true),
  );
}

export default {
  toTitleCase,
  copy,
  isAValidEmail,
  isAValidUSPhoneNumber,
  ifValInLocalStorageSetVal,
  formatUSCurrency,
  formatUSCurrencyWithTwoDecimals,
  formatUSCurrencyWithFourDecimals,
  commaEvery3rdChar,
  formatNumericValue,
  maxCharsAndValue,
  onlyNums,
  formatInputToNumWith2Decimals,
  formatPhoneNumber,
  formatZipCode,
  isAValidEINNumber,
  isAValidZipCode,
  concatCharacter,
  doesNumHaveLeading0s,
  removeCommas,
  trimLeading0s,
  camelize,
  getUserId,
  getCompanyIdFromBackend,
  getTransactionIdFromBackend,
  getDBTransactionData,
  timeOfDayGreeting,
  getUserInfoFromLocalStorage,
  setUserInfoToLocalStorage,
  removeUserInfoFromLocalStorage,
  getHomepageDataFromDB,
  removeAllInitioUserInfo,
  removeAllInitioUserInfoSignedOutUsers,
  sendHtmlEmail,
  sendHtmlEmailWithAttachment,
};

export function pick(object, keys) {
  return keys.reduce((acc, key) => {
    acc[key] = object[key];
    return acc;
  }, {});
}
